import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBIcon,
  MDBBtn,
} from "mdb-react-ui-kit";

function Cart({ cartItems }) {
  return (
    <MDBContainer fluid className="my-5 text-center">
      <h4 className="mt-4 mb-5">
        <strong>Cart</strong>
      </h4>

      <MDBRow>
        {cartItems.map((item) => (
          <MDBCol md="12" lg="4" className="mb-4" key={item.id}>
            <MDBCard>
              <MDBCardBody>
                <MDBCardImage src={item.imageSrc} fluid className="w-100" />
                <h5 className="card-title mt-3">{item.name}</h5>
                <p>{item.price}</p>
                <MDBBtn color="danger" size="sm">
                  Remove
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        ))}
      </MDBRow>
    </MDBContainer>
  );
}

export default Cart;
