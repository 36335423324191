import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

function Timer() {
  const [time, setTime] = useState(0); // 10 minutes en secondes
  const [isActive, setIsActive] = useState(false);
  const [IsMode, setIsMode] = useState(false);
  
  
  const [temps, setTemps] = useState(0);
  const [date, setDate] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTemps(
        new Date().toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        })
        );
      setDate(
        new Date().toLocaleDateString([], {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
        );

    }, 100);
    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    let intervalId;

    if (isActive) {
      intervalId = setInterval(() => {
        setTime(time => time - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, time]);

  const handleModeClick = () => {
    if(!isActive){
        setIsMode(IsMode => !IsMode);
    }
  };

  const handleClearClick = () => {
    if(!isActive){
        setTime(0);
    }
  };

  const handleProgClick = () => {
    if(!isActive){
        setTime(time + 1 * 60);
    }
  };

  const handleSyncClick = () => {
    setTime(prevTime => {
        const newTime = Math.floor(prevTime / 60) * 60; // round down to nearest minute
        return newTime;
      });
  };

  const handleStartStopClick = () => {
    setIsActive(isActive => !isActive);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <Container fluid>
        {IsMode ? (
            <Col className="text-center">
                <Row>
                    <Col></Col>
                    <Col>
                        <div className="d-grid gap-2">
                          <Button variant="danger" size="lg" onClick={handleStartStopClick}>{isActive ? 'Stop' : 'Start'}</Button>
                        </div>
                    </Col>
                    <Col></Col>
                </Row>
                <Row>
                    <Col xs={0} lg={3}></Col>
                    <Col className="text-center"  xs={12} lg={6}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 120">
                            <text textAnchor="middle" x="125" y="90" fill="#eb3434" fontFamily='Helvetica' fontSize="100" fontWeight="bold">{formatTime(Math.abs(time))}</text>
                        </svg>
                        </Col>
                    <Col xs={0} lg={3}></Col>
                </Row>
                <Row>
                    <Col lg={3}></Col>
                    <Col className="text-center"   xs={4}lg={2}>
                        <div className="d-grid gap-2">
                          <Button variant="primary" size="lg" onClick={handleProgClick}>Prog</Button>
                        </div>
                    </Col>
                    <Col className="text-center"   xs={4}lg={2}>
                        <div className="d-grid gap-2">
                          <Button variant="success" size="lg" onClick={handleSyncClick}>Sync</Button>
                        </div>
                    </Col>
                    <Col className="text-center"   xs={4}lg={2}>
                        <div className="d-grid gap-2">
                          <Button variant="warning" size="lg" onClick={handleClearClick}>Clear</Button>
                        </div>
                    </Col>
                    <Col lg={3}></Col>
                </Row>
            </Col>
            ) : (
            <Row>
                <Col className="text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 100">
                        <text textAnchor="middle" x="125" y="20" fill="#eb3434"  fontSize="8" fontWeight="bold">{date}</text>
                        <text textAnchor="middle" x="125" y="80" fill="#eb3434" fontFamily='Helvetica' fontSize="50" fontWeight="bold">{temps}</text>
                    </svg>
                </Col>
            </Row>
            )
        }
        <br></br>
        <br></br>
        <Row>
            <Col xs={3} lg={4}></Col>
            <Col className="text-center"  xs={6} lg={4}>
                <div>
                  <Button variant="outline-secondary" size="lg" onClick={handleModeClick}>Mode</Button>
                </div>
            </Col>
            <Col xs={3} lg={4}></Col>
        </Row>

      {/*<Row xs={4}>
        
        <Col className="text-center">
            <div className="d-grid gap-2">
              <Button variant="primary" size="lg" onClick={handleModeClick}>Mode</Button>
            </div>
        </Col>
        <Col className="text-center">
            <div className="d-grid gap-2">
              <Button variant="primary" size="lg" onClick={handleProgClick}>Prog</Button>
            </div>
        </Col>
        <Col className="text-center">
            <div className="d-grid gap-2">
              <Button variant="primary" size="lg" onClick={handleSyncClick}>Sync</Button>
            </div>
        </Col>
        <Col className="text-center">
            <div className="d-grid gap-2">
              <Button variant="primary" size="lg" onClick={handleClearClick}>Clear</Button>
            </div>
        </Col>
        </Row>*/}

    </Container>
  );
}

export default Timer;
