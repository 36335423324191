import React, { useState, useEffect } from 'react';
//import { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';



import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';


import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl'
import Select from 'react-select';


import { BsSearch,
  BsViewList,
  BsFillCursorFill, BsFillGeoAltFill, BsGeoAltFill, BsGeoAlt, BsFillGeoAlt, BsFillGeoFill, BsGeoFill, BsGeo
} from "react-icons/bs";


import {Dirsvg} from "./dir";

import Clock from "./montre";

import WindStat from "./Stat";
import Wave_line from "./wave";

import Station from "./Station";

import InstallBanner from "./installable";

import dayjs, { Dayjs } from 'dayjs';
import DateTimeRangeFieldValue from './Component/DateTimeRangeField';
import Info from './Component/info';


import { sum } from '@tensorflow/tfjs';

import moment from 'moment';
moment.locale(); // Active la localisation automatique en utilisant la région par défaut du navigateur


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);











// Obtenir le fuseau horaire de l'utilisateur
const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;



const Linechart = ({ ktskmh, setktskmh, selectstation, spot, setSpot, pointSource, Long, Lat, setLong, setLat }) => {

  const unitWind = ktskmh == 1 ? 'kts' : ktskmh == 1.852 ? 'km/h' : ktskmh == (1.852 / 3.6) ? 'm/s' : '';
  const options_SPD = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,

    scales:{
      x: {
        ticks: {
          maxTicksLimit: 12 // limiter le nombre de labels à 5
        }
      },
      y:{
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: `Vent [${unitWind}]`,
      },
    },
  };

  const options_Dir = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
    scales:{
      x: {
        ticks: {
          maxTicksLimit: 12 // limiter le nombre de labels à 5
        }
      },
      y:{
        min: 0,
        max: 360,
        ticks: {
          // forces step size to be 50 units
          stepSize: 45
        },
        grid:{
          //color: ['grey'],
          display: 'true',
        }
      },
      y1: {
        type: 'category',
        labels: ['N','W','S', 'E', 'N'],
        position: 'right',
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Direction [deg°]',
      },
    },
  };

  const options_WVHT = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,

    scales:{
      x: {
        ticks: {
          maxTicksLimit: 12 // limiter le nombre de labels à 5
        }
      },
      y:{
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Hauteur [m]',
      },
    },
  };

  const options_PD = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,

    scales:{
      x: {
        ticks: {
          maxTicksLimit: 12 // limiter le nombre de labels à 5
        }
      },
      y:{
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Période [s]',
      },
    },
  };

  const options_MWD = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
    scales:{
      x: {
        ticks: {
          maxTicksLimit: 12 // limiter le nombre de labels à 5
        }
      },
      y:{
        min: 0,
        max: 360,
        ticks: {
          // forces step size to be 50 units
          stepSize: 45
        },
        grid:{
          //color: ['grey'],
          display: 'true',
        }
      },
      y1: {
        type: 'category',
        labels: ['N','W','S', 'E', 'N'],
        position: 'right',
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Direction [deg°]',
      },
    },
  };


  const options_EAU = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,

    scales:{
      x: {
        ticks: {
          maxTicksLimit: 12 // limiter le nombre de labels à 5
        }
      },
      y:{
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Température de l\'eau [°C] à une profondeur de:',
      },
    },
  };

  const options_Temp = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
    scales:{
      x: {
        ticks: {
          maxTicksLimit: 7 // limiter le nombre de labels à 5
        }
      },
      y:{
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Température de l\'air [°C]',
      },
    },
  };

  const options_Hum = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
    scales:{
      x: {
        ticks: {
          //autoSkip: true,
          maxTicksLimit: 7 // limiter le nombre de labels à 5
        }
      },
      y:{
        min: 0,
        max: 100,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Humidité de l\'air [%]',
      },
    },
  };
  // const options_Pluie = {
  //   maintainAspectRatio: false,	// Don't maintain w/h ratio
  //   //responsive: true,
  //   scales:{
  //     x: {
  //       ticks: {
  //         //autoSkip: true,
  //         skipNull: true,
  //         maxTicksLimit: 7 // limiter le nombre de labels à 5
  //       }
  //     },
  //     y:{
  //       type: 'linear',
  //       position: 'left', // Positionner l'axe Y à gauche
  //       text: 'Rain mm', // Légende de l'axe Y pour les valeurs de mm
  //       min: 0, // Commencer l'axe Y à zéro
  //       display: true,
  //     },
  //     y2: {
  //       type: 'linear',
  //       position: 'right', // Positionner l'axe Y à gauche
  //       text: 'Rain rate mm/h', // Légende de l'axe Y pour les valeurs de mm
  //       min: 0, // Commencer l'axe Y à zéro
  //       display: true,
  //     },
  //   },
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: 'top',
  //     },
  //     title: {
  //       display: true,
  //       text: 'Rain',
  //     },
  //   },
  // };
  const options_Pluie = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
    scales:{
      x: {
        ticks: {
          //autoSkip: true,
          skipNull: true,
          maxTicksLimit: 7 // limiter le nombre de labels à 5
        }
      },
      y:{
        min: 0, // Commencer l'axe Y à zéro
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Précipitations [mm]',
      },
    },
  };

  const options_Averse = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
    scales:{
      x: {
        ticks: {
          //autoSkip: true,
          skipNull: true,
          maxTicksLimit: 7 // limiter le nombre de labels à 5
        }
      },
      y:{
        min: 0, // Commencer l'axe Y à zéro
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Intensité de précipitations [mm/h]',
      },
    },
  };

  const options_Sol = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
    scales:{
      x: {
        ticks: {
          //autoSkip: true,
          maxTicksLimit: 7 // limiter le nombre de labels à 5
        }
      },
      y:{
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Ensoleillement [W/m²]',
      },
    },
  };
  const options_Press = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    //responsive: true,
    scales:{
      x: {
        ticks: {
          //autoSkip: true,
          maxTicksLimit: 7 // limiter le nombre de labels à 5
        }
      },
      y:{
        ticks: {
          callback: function (value, index, values) {
            return value.toFixed(1); // Arrondir au dixième
          }
        }
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Pression Atmosphérique [hPa]',
      },
    },
  };


// const Linechart = (props) => {
// const Linechart = ({ ktskmh, setktskmh, selectstation, spot, setSpot, pointSource, Long, Lat }) => {

  // console.log('pointSource')
  // console.log(pointSource)

  // ktskmh, setktskmh, selectstation, slectspot, pointSource, Long, Lat


  const [data_WBGT, setdata_WBGT] = useState({
    labels: [],
    datasets: [
      {
        label: 'WBGT',
        data: [],
        borderColor: 'rgb(255, 205, 86)',
        backgroundColor: 'rgba(255, 205, 86, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
      {
        label: 'WBT',
        data: [],
        borderColor: 'rgb(154, 102, 255)',
        backgroundColor: 'rgba(154, 102, 255, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
      {
        label: 'TG',
        data: [],
        borderColor: 'rgb(5, 5, 5)',
        backgroundColor: 'rgba(5, 5, 5, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
      {
        label: 'TA',
        data: [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
    ],
  });

  const [data_WBGT_small, setdata_WBGT_small] = useState({
    labels: [],
    datasets: [
      {
        label: 'WBGT',
        data: [],
        borderColor: 'rgb(255, 205, 86)',
        backgroundColor: 'rgba(255, 205, 86, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
    ],
  });



  const [data_Wspd, setdata_Wspd] = useState({
    labels: [],
    datasets: [
      {
        label: 'Vent',
        data: [],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
      {
        label: 'Rafale',
        data: [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
      {
        label: 'Vent minimum',
        data: [],
        borderColor: 'rgb(255, 216, 123)',
        backgroundColor: 'rgba(255, 230, 173)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
    ],
  });

  const [data_Wdir, setdata_Wdir] = useState({
    labels: [],
    datasets: [
      {
        label: 'Vent',
        data: [],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
      {
        label: 'Rafale',
        data: [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
    ],
  });

  const [data_WVHT, setdata_WVHT] = useState({
    labels: [],
    datasets: [
      {
        label: 'Hauteur significative des vagues',
        data: [],
        borderColor: 'rgb(255, 158, 64)',
        backgroundColor: 'rgba(255, 158, 64, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
    ],
  });
  const [data_MWD, setdata_MWD] = useState({
    labels: [],
    datasets: [
      {
        label: 'Direction moyenne des vagues',
        data: [],
        borderColor: 'rgb(255, 158, 64)',
        backgroundColor: 'rgba(255, 158, 64, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
    ],
  });

  const [data_PD, setdata_PD] = useState({
    labels: [],
    datasets: [
      {
        label: 'Période de vague dominante',
        data: [],
        borderColor: 'rgb(154, 102, 255)',
        backgroundColor: 'rgba(154, 102, 255, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
      {
        label: 'Période moyenne de vague',
        data: [],
        borderColor: 'rgb(76, 192, 192)',
        backgroundColor: 'rgba(76, 192, 192, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
    ],
  });

  
  const [data_Eau, setdata_Eau] = useState({
    labels: [],
    datasets: [
      {
        label: '5 cm',
        data: [],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
      {
        label: '65 cm',
        data: [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
      {
        label: '125 cm',
        data: [],
        borderColor: 'rgb(255, 216, 123)',
        backgroundColor: 'rgba(255, 230, 173)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
    ],
  });

  const [data_Temp, setdata_Temp] = useState({
    labels: [],
    datasets: [
      {
        label: 'Température',
        data: [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
    ],
  });
  const [data_Hum, setdata_Hum] = useState({
    labels: [],
    datasets: [
      {
        label: 'Humidité',
        data: [],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        fill: true, // Remplir l'espace sous la courbe
      },
    ],
  });
  const [data_Press, setdata_Press] = useState({
    labels: [],
    datasets: [
      {
        label: 'Pression',
        data: [],
        borderColor: 'rgb(151, 151, 151)',
        backgroundColor: 'rgba(151, 151, 151, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
    ],
  });
  const [data_Pluie, setdata_Pluie] = useState({
    labels: [],
    datasets: [
      {
        label: 'Précipitations',
        data: [],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
    ],
  });
  const [data_Averse, setdata_Averse] = useState({
    labels: [],
    datasets: [
      {
        label: 'Intensité de précipitations',
        data: [],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
      },
    ],
  });
  const [data_Soleil, setdata_Soleil] = useState({
    labels: [],
    datasets: [
      {
        label: 'Ensoleillement',
        data: [],
        borderColor: 'rgb(255, 239, 2)',
        backgroundColor: 'rgba(255, 239, 2, 0.5)',
        tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        fill: true,
      },
    ],
  });


  const [showSearch, setShowSearch] = useState(false); // état initial de 6 heures
  const [hours, setHours] = useState(6); // état initial de 6 heures
  let initialStartDate = dayjs().subtract(6, 'hours');
  // let mtn = dayjs();
  // let initialEndDate = mtn;
  let initialEndDate = dayjs();
  const [DateTimeRange, setDateTimeRange] = useState([initialStartDate, initialEndDate]);

  // const [spot, setSpot] = useState(slectspot); //'1-meteowind-iot-pro'); // état initial de 6 heures
  // const [spot, setSpot] = useState(props.slectspot); //'1-meteowind-iot-pro'); // état initial de 6 heures
  // const pointSource= props.pointSource;
  
  // const [Lat, setLat]= useState(props.Lat);
  // const [Long, setLong]= useState(props.Long);
  // // const LaSource = LaSource;
  // console.log('slectspot')
  // console.log(slectspot)
  
  const [received_at, setreceived_at] = useState([]);
  const [received_time, setreceived_time] = useState([]);
  const [received_date, setreceived_date] = useState([]);
  const [Wind_ave, setWind_ave] = useState([]);
  const [Wind_3sgust, setWind_3sgust] = useState([]);
  const [Wind_3smin, setWind_3smin] = useState([]);
  const [Wind_stdev, setWind_stdev] = useState([]);
  const [Dir_ave, setDir_ave] = useState([]);
  const [Dir_3sgust, setDir_3sgust] = useState([]);
  const [Dir_stdev, setDir_stdev] = useState([]);
  const [gust_3stime, setGust_3stime] = useState([]);
  const [Hum_SHT, setHum_SHT] = useState([]);
  const [TempC_DS, setTempC_DS] = useState([]);
  const [TempC_SHT, setTempC_SHT] = useState([]);
  const [WTMP, setWTMP] = useState([]);
  const [Temp_Red, setTemp_Red] = useState([]);
  const [Temp_White, setTemp_White] = useState([]);

  const [Type, setType] = useState([]);
  const [T_min, setT_min] = useState([]);
  const [T_max, setT_max] = useState([]);
  const [Pressure, setPressure] = useState([]);
  const [Irradiation, setIrradiation] = useState([]);
  const [Irr_max, setIrr_max] = useState([]);

  const [Rain, setRain] = useState([]);
  const [Rain_time, setRain_time] = useState([]);
  const [Frost_alert, setFrost_alert] = useState([]);
  const [Heater_on, setHeater_on] = useState([]);
  const [RainIntensityCorrection, setRainIntensityCorrection] = useState([]);
  
  const [Rain_rate, setRain_rate] = useState([]); // Ajoutez cet état pour stocker les valeurs de Rain_rate
  const [Rain_mm, setRain_mm] = useState([]); // Ajoutez cet état pour stocker les valeurs de Rain_rate
  
  const [totalRain, setTotalRain] = useState(0);


  const [DewP, setDewP] = useState([]);
  const [Visib, setVisib] = useState([]);
  const [vertVis, setvertVis] = useState([]);
  const [Snow, setSnow] = useState([]);


  const [WVHT, setWVHT] = useState([]); // - Hauteur significative des vagues (en mètres)
  const [DPD, setDPD] = useState([]); // - Période de vague dominante (en secondes)
  const [APD, setAPD] = useState([]); // - Période moyenne de vague (en secondes)
  const [MWD, setMWD] = useState([]); // - Direction moyenne des vagues (en degrés)

  const [PTDY, setPTDY] = useState([]); // - Changement de pression en 3 heures (en hPa)


  const [TIDE, setTIDE] = useState([]); // - Niveau de la marée
  const [hw, sethw ] = useState([]); // - marée haute
  const [hw_time, sethw_time] = useState([]); // - heure marée haute
  const [lw, setlw] = useState([]); // - marée basse
  const [lw_time, setlw_time] = useState([]); // - heure marée basse



  const fetchData = async () => {


    let StartDate = DateTimeRange[0];
    let EndDate = DateTimeRange[1];      
    let formattedStartDate = encodeURIComponent(StartDate.toISOString());
    let formattedEndDate = encodeURIComponent(EndDate.toISOString());

    let url1 = `./jsondata/spots/spots.php?spot=${encodeURIComponent(spot)}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    // let url1 = `./jsondata/spots/spots.php?spot=${encodeURIComponent(spot)}&hours=${encodeURIComponent(hours)}`;  // Déclarez la variable en dehors des blocs if

    // const foundFeature = props.pointSource.data.features.find(feature => feature.properties.spot_id === spot);
    const foundFeature = pointSource.data.features.find(feature => feature.properties.spot_id === spot);
    console.log('source')
    console.log(foundFeature.properties.source)

    if(foundFeature.properties.source=='METAR'){
      // url1 =`./jsondata/spots/weather.php?spot=${encodeURIComponent(spot)}&hours=${encodeURIComponent(hours)}`;
      url1 =`./jsondata/spots/weather.php?spot=${encodeURIComponent(spot)}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    }

    if(foundFeature.properties.source=='MeteoSwiss'){
      // url1 =`./jsondata/spots/weather.php?spot=${encodeURIComponent(spot)}&hours=${encodeURIComponent(hours)}`;
      url1 = `./jsondata/spots/swiss.php?spot=${encodeURIComponent(spot)}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    }

    

    if(foundFeature.properties.source=='NOAA'){
      url1 =`./jsondata/spots/NOAA.php?spot=${encodeURIComponent(spot)}&hours=${encodeURIComponent(hours)}`;
    }

    if(foundFeature.properties.source=='SHIP'){
      url1 =`./jsondata/spots/SHIP.php?lat=${encodeURIComponent(Lat)}&long=${encodeURIComponent(Long)}&hours=${encodeURIComponent(hours)}`;
    }

    if(foundFeature.properties.source=='Costal'){
      // url1 =`./jsondata/spots/coastalmonitoring.php?spot=${encodeURIComponent(spot)}&hours=${encodeURIComponent(hours)}`;
      url1 =`./jsondata/spots/coastalmonitoring.php?spot=${encodeURIComponent(spot)}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    }

    // console.log('url')
    // console.log(url1)
    // else {
    //   url1 = `./jsondata/spots/spots.php?spot=${encodeURIComponent(spot)}&hours=${encodeURIComponent(hours)}`;    //'https://truewindspeed.ch/jsondata/data.json'; //
    // }

    // let url1 = `./jsondata/spots/spots.php?spot=${encodeURIComponent(spot)}&hours=${encodeURIComponent(hours)}`;

    // if(LaSource=='METAR'){
    //   url1 =`./jsondata/spots/weather.php?spot=${encodeURIComponent(spot)}&hours=${encodeURIComponent(hours)}`;
    // }
    // else {
    //   url1 = `./jsondata/spots/spots.php?spot=${encodeURIComponent(spot)}&hours=${encodeURIComponent(hours)}`;    //'https://truewindspeed.ch/jsondata/data.json'; //
    // }


    // const url1 = `./jsondata/spots/spots.php?spot=${encodeURIComponent(spot)}&hours=${encodeURIComponent(hours)}`;    //'https://truewindspeed.ch/jsondata/data.json'; //
    // // const url1 = `./jsondata/spots/spots.php?spot=${encodeURIComponent(spot)}`; //'https://truewindspeed.ch/jsondata/data.json'; //
    // // const url2 = `./jsondata/spots/${spot}.json`;
    // // const url1 ='https://beta.aviationweather.gov/cgi-bin/data/metar.php?ids=LSGG&format=json';
    // // const url1 =`./jsondata/spots/weather.php`;
    // const url1 =`./jsondata/spots/weather.php?spot=${encodeURIComponent(spot)}&hours=${encodeURIComponent(hours)}?spot=${encodeURIComponent(spot)}`;
    // // const url2 = `./jsondata/spots/${spot}.php`; //'https://truewindspeed.ch/jsondata/apijson.php'; //
    // // const url1 = './jsondata/data.json'; //'https://truewindspeed.ch/jsondata/data.json'; //
    // // const url2 = './jsondata/apijson.php'; //'https://truewindspeed.ch/jsondata/apijson.php'; //

    const received_at = [];
    const received_time = [];
    const received_date = [];
    const Wind_ave = [];
    const Wind_3sgust = [];
    const Wind_3smin = [];
    const Dir_ave = [];
    const Dir_3sgust = [];
    const Dir_stdev = []; // ajout de Dir_stdev
    const gust_3stime = []; // ajout de gust_3stime
    const Hum_SHT = []; // ajout de Hum_SHT
    const TempC_DS = []; // ajout de TempC_DS
    const TempC_SHT = []; // ajout de TempC_SHT
    const WTMP = []; // ajout de WTMP
    const Temp_Red = []; // ajout de Temp_Red
    const Temp_White = []; // ajout de Temp_Whitey
    
    const Type = [];
    const T_min = [];
    const T_max = [];
    const Pressure = []; // ajout de gust_3stime
    const Irradiation = [];
    const Irr_max = [];

    const Rain = [];
    const Rain_time = [];
    const Frost_alert = [];
    const Heater_on = [];
    const RainIntensityCorrection = [];

    const Rain_rate = [];    
    const Rain_mm = [];



    const DewP = [];
    const Visib = [];
    const vertVis = [];
    const Snow = [];


    const WVHT = []; // - Hauteur significative des vagues (en mètres)
    const DPD = []; // - Période de vague dominante (en secondes)
    const APD = []; // - Période moyenne de vague (en secondes)
    const MWD = []; // - Direction moyenne des vagues (en degrés)

    const PTDY = []; // - Changement de pression en 3 heures (en hPa)



    const TIDE = []; // - Niveau de la marée
    const hw  = []; // - marée haute
    const hw_time = []; // - heure marée haute
    const lw = []; // - marée basse
    const lw_time = []; // - heure marée basse




    // /*/
    await Promise.race([
      fetch(url1),
      // fetch(url2)
    ])
      .then((data) => {
        const res = data.json();
        return res;
      })
      .then((res) => {
        res.reverse();

        for (const val of res) {

            if(hours>24 || hours<=0){
              received_at.push(
                new Date(val.received_at+ 'Z').toLocaleTimeString([], {  //.toLocaleDateString
                  timeZone: userTimezone,
                  //year: '2-digit',
                  month: '2-digit',
                  day: '2-digit',
                  hour: 'numeric',
                  hour12: false,
                  minute: '2-digit',
                }));
            }
            else{
              received_at.push(
                new Date(val.received_at+ 'Z').toLocaleTimeString([], {
                  timeZone: userTimezone,
                  hour: '2-digit',
                  minute: '2-digit',
                  // second: '2-digit',
                }));
            }
            received_time.push(
              // new Date(val.received_at)
              new Date(val.received_at+ 'Z').toLocaleTimeString([], {  //.toLocaleDateString
                timeZone: userTimezone,
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                hour12: false,
                minute: '2-digit',
                second:'2-digit'
              })
            );
            received_date.push(
              new Date(val.received_at+ 'Z').toLocaleTimeString([], {  //.toLocaleDateString
                timeZone: userTimezone,
                //year: '2-digit',
                month: '2-digit',
                day: '2-digit',
                hour: 'numeric',
                hour12: false,
                minute: '2-digit',
              }));

              val.Wind_ave !== null ? Wind_ave.push(val.Wind_ave * ktskmh) : Wind_ave.push(val.Wind_ave);
              // Wind_ave.push(val.Wind_ave*ktskmh);
              val.Wind_3sgust !== null ? Wind_3sgust.push(val.Wind_3sgust * ktskmh) : Wind_3sgust.push(val.Wind_3sgust);
              // Wind_3sgust.push(val.Wind_3sgust*ktskmh);
              val.Wind_3smin !== null ? Wind_3smin.push(val.Wind_3smin * ktskmh) : Wind_3smin.push(val.Wind_3smin);
              // Wind_3smin.push(val.Wind_3smin*ktskmh);
              Wind_stdev.push(val.Wind_stdev);
              Dir_ave.push(val.Dir_ave);
              Dir_3sgust.push(val.Dir_3sgust);
              Dir_stdev.push(val.Dir_stdev);
              gust_3stime.push(val.gust_3stime);
  
              Hum_SHT.push(val.Hum_SHT);
              TempC_SHT.push(val.TempC_SHT);
              // console.log('val.TempC_SHT')
              // console.log(val.TempC_SHT)
  
              WTMP.push(val.WTMP);
              Temp_Red.push(val.Temp_Red);
              Temp_White.push(val.Temp_White); 
  
              Type.push(val.Type);
              T_min.push(val.T_min);
              T_max.push(val.T_max);
              Irradiation.push(val.Irradiation);
              Irr_max.push(val.Irr_max);

              Pressure.push(val.QNH);
              PTDY.push(val.PTDY); // - Changement de pression en 3 heures (en hPa)

          
              Frost_alert.push(val.Frost_alert);
              Heater_on.push(val.Heater_on);
  
              Rain.push(val.Rain); // compteur de clics
              RainIntensityCorrection.push(val.RainIntensityCorrection); // correction en forte pluie
  
              Rain_time.push(val.Rain_time); // temps entre 2 clics
              Rain_rate.push(val.Rain_rate); // valeur calcullée a partir de Rain_time
              Rain_mm.push(val.Rain_mm); // valeur calcullée a partir de Rain_time


              DewP.push(val.DewP);
              Visib.push(val.Visib);
              vertVis.push(val.vertVis);
              Snow.push(val.Snow);

              // console.log('val.WVHT')
              // console.log(val.WVHT)
              WVHT.push(val.WVHT); // - Hauteur significative des vagues (en mètres)
              DPD.push(val.DPD); // - Période de vague dominante (en secondes)
              APD.push(val.APD); // - Période moyenne de vague (en secondes)
              MWD.push(val.MWD); // - Direction moyenne des vagues (en degrés)
          
          
          
          
              TIDE.push(val.TIDE); // - Niveau de la marée
              hw.push(val.hw); // - marée haute
              hw_time.push(val.hw_time); // - heure marée haute
              lw.push(val.lw); // - marée basse
              lw_time.push(val.lw_time); // - heure marée basse
            

          // }
        }

        setreceived_at(received_at)
        setreceived_time(received_time)
        setreceived_date(received_date)
        setWind_ave(Wind_ave)
        setWind_3sgust(Wind_3sgust)
        setWind_3smin(Wind_3smin)
        setWind_stdev(Wind_stdev)
        setDir_ave(Dir_ave)
        setDir_3sgust(Dir_3sgust)
        setDir_stdev(Dir_stdev)
        setGust_3stime(gust_3stime)
        setTempC_DS(TempC_DS)
        setTempC_SHT(TempC_SHT)
        setWTMP(WTMP)
        setTemp_Red(Temp_Red)
        setTemp_White(Temp_White)


        setType(Type)
        setT_min(T_min)
        setT_max(T_max)
        setPressure(Pressure)
        setHum_SHT(Hum_SHT)
        setIrradiation(Irradiation)
        setIrr_max(Irr_max)
      
        setRain(Rain)
        setRain_time(Rain_time)
        setFrost_alert(Frost_alert)
        setHeater_on(Heater_on)
        setRainIntensityCorrection(RainIntensityCorrection)

        setRain_rate(Rain_rate)
        setRain_mm(Rain_mm)

        setDewP(DewP)
        setVisib(Visib)
        setvertVis(vertVis)
        setSnow(Snow)

        setWVHT(WVHT) // - Hauteur significative des vagues (en mètres)
        setDPD(DPD) // - Période de vague dominante (en secondes)
        setAPD(APD) // - Période moyenne de vague (en secondes)
        setMWD(MWD) // - Direction moyenne des vagues (en degrés)
    
        setPTDY(PTDY) // - Changement de pression en 3 heures (en hPa)
    
    
    
        setTIDE(TIDE) // - Niveau de la marée
        sethw(hw) // - marée haute
        sethw_time(hw_time) // - heure marée haute
        setlw(lw) // - marée basse
        setlw_time(lw_time) // - heure marée basse

        // Convertir les chaînes de caractères en valeurs numériques et filtrer les valeurs null
        const numericValues = Rain_mm.map(value => parseFloat(value)).filter(value => !isNaN(value) && value !== null && !isNaN(value));
        // Calculer la somme des valeurs numériques
        const sumRain = numericValues.reduce((acc, value) => acc + value, 0);
        const roundedSumRain = sumRain.toFixed(2);
        setTotalRain(roundedSumRain); // Vous pouvez ajouter cette fonction pour mettre à jour la quantité totale dans le state



        // Filtrer les valeurs null de Wind_ave et leurs correspondances dans received_at
        const received_atWind = [];
        const plot_WindAve = [];
        const plot_Wind_3sgust = [];
        const plot_Wind_3smin = [];
        const plot_Dir_ave = [];
        const plot_Dir_3sgust = [];
        for (let i = 0; i < Wind_ave.length; i++) {
          if (Wind_ave[i] !== null) {
            received_atWind.push(received_at[i]);
            plot_WindAve.push(Wind_ave[i]);
            plot_Wind_3sgust.push(Wind_3sgust[i]);
            plot_Wind_3smin.push(Wind_3smin[i]);
            plot_Dir_ave.push(Dir_ave[i]);
            plot_Dir_3sgust.push(Dir_3sgust[i]);
          }
        }
        setdata_Wspd({
          labels: received_atWind,
          datasets: [
            {
              label: 'Vent moyen',
              data: plot_WindAve,
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
            {
              label: 'Rafale',
              data: plot_Wind_3sgust,
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
            {
              label: 'Vent minimum',
              data: plot_Wind_3smin,
              borderColor: 'rgb(255, 216, 123)',
              backgroundColor: 'rgba(255, 230, 173)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
          ],
        });
        setdata_Wdir({
          labels: received_atWind,
          datasets: [
            {
              label: 'Vent',
              data: plot_Dir_ave,
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
              tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
            {
              label: 'Rafale',
              data: plot_Dir_3sgust,
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
          ],
        });


        const WVHT_received_at = [];
        const plot_WVHT = [];
        for (let i = 0; i < WVHT.length; i++) {
          if (WVHT[i] !== null) {
            WVHT_received_at.push(received_at[i]);
            plot_WVHT.push(WVHT[i]);
          }
        }
        setdata_WVHT({
          labels: WVHT_received_at,
          datasets: [
            {
              label: 'Hauteur significative des vagues',
              data: plot_WVHT,
              borderColor: 'rgb(255, 158, 64)',
              backgroundColor: 'rgba(255, 158, 64, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
          ],
        });



        const MWD_received_at = [];
        const plot_MWD = [];
        for (let i = 0; i < MWD.length; i++) {
          if (MWD[i] !== null) {
            MWD_received_at.push(received_at[i]);
            plot_MWD.push(MWD[i]);
          }
        }
        setdata_MWD({
          labels: MWD_received_at,
          datasets: [
            {
              label: 'Direction moyenne des vagues',
              data: plot_MWD,
              borderColor: 'rgb(255, 158, 64)',
              backgroundColor: 'rgba(255, 158, 64, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
          ],
        });
      
        const PD_received_at = [];
        const plot_PD = [];
        if (DPD.some(value => value > 0)){
          for (let i = 0; i < DPD.length; i++) {
            if (DPD[i] !== null) {
              PD_received_at.push(received_at[i]);
              plot_PD.push(DPD[i]);
            }
          }
        }
        else if(APD.some(value => value > 0)){
          for (let i = 0; i < APD.length; i++) {
            if (APD[i] !== null) {
              PD_received_at.push(received_at[i]);
              plot_PD.push(APD[i]);
            }
          }
        }
        
        
        setdata_PD({
          labels: PD_received_at,
          datasets: [
            {
              label: 'Période des vagues dominantes',
              data: plot_PD,
              borderColor: 'rgb(154, 102, 255)',
              backgroundColor: 'rgba(154, 102, 255, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
            {
              label: 'Période moyenne des vagues',
              data: plot_PD,
              borderColor: 'rgb(76, 192, 192)',
              backgroundColor: 'rgba(76, 192, 192, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
          ],
        });




        // const MWD_received_at = [];
        // const plot_MWD = [];
        // for (let i = 0; i < MWD.length; i++) {
        //   if (MWD[i] !== null) {
        //     MWD_received_at.push(received_at[i]);
        //     plot_MWD.push(MWD[i]);
        //   }
        // }
        // setdata_MWD({
        //   labels: MWD_received_at,
        //   datasets: [
        //     {
        //       label: 'Direction moyenne des vagues',
        //       data: plot_MWD,
        //       borderColor: 'rgb(255, 158, 64)',
        //       backgroundColor: 'rgba(255, 158, 64, 0.5)',
        //       tension: 0.3,
        //       spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        //     },
        //   ],
        // });




        // const PD_received_at = [];
        // const plot_DPD = [];
        // const plot_APD = [];
        // // if (DPD.sum > 0) {
        // //   for (let i = 0; i < PD.length; i++) {
        // //     if (DPD[i] !== null) {
        // //       PD_received_at.push(received_at[i]);
        // //       plot_DPD.push(DPD[i]);
        // //       plot_APD.push(APD[i]);
        // //     }
        // //   }
        // // }
        // // else if (APD.sum > 0) {
        // //   for (let i = 0; i < PD.length; i++) {
        // //     if (APD[i] !== null) {
        // //       PD_received_at.push(received_at[i]);
        // //       plot_DPD.push(DPD[i]);
        // //       plot_APD.push(APD[i]);
        // //     }
        // //   }
        // // }
        // for (let i = 0; i < PD.length; i++) {
        //   if (DPD[i] !== null) {
        //     PD_received_at.push(received_at[i]);
        //     plot_DPD.push(DPD[i]);
        //     plot_APD.push(APD[i]);
        //   }
        // }
        // setdata_PD({
        //   labels: PD_received_at,
        //   datasets: [
        //     {
        //       label: 'Période de vague dominante',
        //       data: plot_DPD,
        //       borderColor: 'rgb(154, 102, 255)',
        // backgroundColor: 'rgba(154, 102, 255, 0.5)',
        //       tension: 0.3,
        //       spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        //     },
        //     {
        //       label: 'Période moyenne de vague',
        //       data: plot_APD,
        //       borderColor: 'rgb(76, 192, 192)',
        //       backgroundColor: 'rgba(76, 192, 192, 0.5)',
        //       tension: 0.3,
        //       spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        //     },
        //   ],
        // });

        


        // Filtrer les valeurs null de Wind_ave et leurs correspondances dans received_at
        const received_atEau = [];
        const plot_Temp_White = [];
        const plot_WTMP = [];
        const plot_Temp_Red = [];
        for (let i = 0; i < WTMP.length; i++) {
          if (WTMP[i] !== null) {
            received_atEau.push(received_at[i]);
            plot_Temp_White.push(Temp_White[i]);
            plot_WTMP.push(WTMP[i]);
            plot_Temp_Red.push(Temp_Red[i]);
          }
        }
        setdata_Eau({
          labels: received_atEau,
          datasets: [
            {
              label: '5 cm',
              data: plot_Temp_Red,
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
              tension: 0.3,
        spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
            {
              label: '65 cm',
              data: plot_WTMP,
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
            {
              label: '125 cm',
              data: plot_Temp_White,
              borderColor: 'rgb(255, 216, 123)',
              backgroundColor: 'rgba(255, 230, 173)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
          ],
        });


        // Filtrer les valeurs null de TempC_SHT et leurs correspondances dans received_at
        const received_atTempHum = [];
        const plot_TempC_SHT = [];
        const plot_Hum_SHT = [];
        for (let i = 0; i < TempC_SHT.length; i++) {
          if (TempC_SHT[i] !== null) {
            received_atTempHum.push(received_at[i]);
            plot_TempC_SHT.push(TempC_SHT[i]);
            plot_Hum_SHT.push(Hum_SHT[i]);
          }
        }
        setdata_Temp({
          labels: received_atTempHum,
          datasets: [
            {
              label: 'Température',
              data: plot_TempC_SHT,
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
          ],
        });

        setdata_Hum({
          labels: received_atTempHum,
          datasets: [
            {
              label: 'Humidité',
              data: plot_Hum_SHT,
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
              fill: true,
            },
          ],
        });


        // Filtrer les valeurs null de TempC_SHT et leurs correspondances dans received_at
        const received_atPressure = [];
        const plot_Pressure = [];
        for (let i = 0; i < Pressure.length; i++) {
          if (Pressure[i] !== null) {
            received_atPressure.push(received_at[i]);
            plot_Pressure.push(Pressure[i]);
          }
        }
        // const received_atPressure = received_at.filter((_, i) => Pressure[i] !== null);
        // const plot_Pressure = Pressure.filter(p => p !== null);

        setdata_Press({
          labels: received_atPressure,
          datasets: [
            {
              label: 'Pression',
              data: plot_Pressure,
              borderColor: 'rgb(151, 151, 151)',
              backgroundColor: 'rgba(151, 151, 151, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
          ],
        });
        // setdata_Pluie({
        //   labels: received_at,
        //   datasets: [
        //     {
        //       label: 'Rain mm/h',
        //       data: Rain_rate,
        //       type: 'line', // Définir le type comme une ligne
        //       fill: false, // Ne pas remplir l'aire sous la ligne
        //       yAxisID: 'y2',
        //       borderColor: 'rgb(53, 162, 235)',
        //       backgroundColor: 'rgba(53, 162, 235, 0.5)',
        //       // pointRadius: 5, // Augmenter la taille des points
        //       tension: 0.3,
        //       borderWidth: 1,
        //       spanGaps: true // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        //     },
        //     {
        //       label: 'Rain mm',
        //       data: Rain_mm,
        //       type: 'bar', // Définir le type comme une ligne
        //       borderColor: 'rgb(53, 162, 235)',
        //       backgroundColor: 'rgba(53, 162, 235, 0.5)',
        //       yAxisID: 'y',
        //       borderWidth: 1.5,
        //       borderRadius: 10,
        //       // barThickness: 5,
        //       barPercentage: 1.7,
        //       categoryPercentage: 1.7,
        //       // borderSkipped: true,

        //       tension: 0.3,
        //       spanGaps: true // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
        //     },
        //   ],
        // });

        // Filtrer les valeurs null de TempC_SHT et leurs correspondances dans received_at
        const received_atRain = [];
        const plot_Rain_mm = [];
        const plot_Rain_rate = [];
        for (let i = 0; i < Rain_mm.length; i++) {
          if (Rain[i] !== null) {
            received_atRain.push(received_at[i]);
            plot_Rain_mm.push(Rain_mm[i]);
            plot_Rain_rate.push(Rain_rate[i]);
          }
        }
        setdata_Pluie({
          labels: received_atRain,
          datasets: [
            {
              label: 'Précipitations',
              data: plot_Rain_mm,
              type: 'bar', // Définir le type comme une ligne
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
              yAxisID: 'y',
              borderWidth: 1.5,
              borderRadius: 10,
              // barThickness: 5,
              barPercentage: 0.9, //bar est dans category
              categoryPercentage: 1,
              // borderSkipped: true,
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
          ],
        });
        setdata_Averse({
          labels: received_atRain,
          datasets: [
            {
              label: 'Intensité de précipitations',
              data: plot_Rain_rate,
              type: 'line', // Définir le type comme une ligne
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
            },
          ],
        });


        // Filtrer les valeurs null de TempC_SHT et leurs correspondances dans received_at
        const received_atIrr = [];
        const plot_Irr = [];
        for (let i = 0; i < Irradiation.length; i++) {
          if (Irradiation[i] !== null) {
            received_atIrr.push(received_at[i]);
            plot_Irr.push(Irradiation[i]);
          }
        }
        setdata_Soleil({
          labels: received_atIrr,
          datasets: [
            {
              label: 'Ensoleillement',
              data: plot_Irr,
              borderColor: 'rgb(255, 239, 2)',
              backgroundColor: 'rgba(255, 239, 2, 0.5)',
              tension: 0.3,
              spanGaps: true, // Ajoutez cette ligne pour ignorer les valeurs nulles entre les points
              fill: true,
            },
          ],
        });












        

      })
      .catch((e) => {
        //console.log('error', e);
      });
  };
  


  useEffect(() => {
    const interval = setInterval(() => { //refreash toutes les x secondes

      if(hours > 0){ // update si StartDate et EndDate ont pas été touché
        let StartDate = dayjs().subtract(hours, 'hours');
        let EndDate = dayjs();
        setDateTimeRange([StartDate, EndDate]);
      }
      else if (hours == 0) { // update le endDate si le endDate a pas été touché
        let StartDate = DateTimeRange[0];
        let EndDate = dayjs();
        setDateTimeRange([StartDate, EndDate]);
      }

      fetchData(); //refreash toutes les x secondes
    }, 10000); //refreash toutes les x000 secondes
    
    fetchData(); // refetch les données lorsque hours est mis à jour
    return () => clearInterval(interval); // refetch les données lorsque hours est mis à jour
  }, [DateTimeRange, hours, spot, ktskmh]); // refetch les données lorsque hours est mis à jour




  useEffect(() => {
    let newRadius=0;
    let newLineWidth=1.5;

    if(hours == 2){
      newRadius=3;
      newLineWidth=3;
    }
    else if(hours == 3){
      newRadius=2;
      newLineWidth=3;
    }
    else if(hours == 6){
      newRadius=2;
      newLineWidth=2.5;
    }
    else if(hours == 12){
      newRadius=1.5;
      newLineWidth=2;
    }
    else if(hours >= 24){
      newRadius=1;
      newLineWidth=1.5;
    }
    
    // Fonction réutilisable pour mettre à jour le rayon des points
    const updatePointRadiusInState = (data, newRadius, newLineWidth, setDataFunction) => {
      setDataFunction((prevData) => ({
        ...prevData,
        datasets: prevData.datasets.map((dataset) => ({
          ...dataset,
          pointRadius: newRadius,
          borderWidth: newLineWidth,
        })),
      }));
    };

    // Utilisation de la fonction pour mettre à jour le rayon des points dans différentes variables de state
    updatePointRadiusInState(data_WBGT_small, newRadius, newLineWidth, setdata_WBGT_small);
    updatePointRadiusInState(data_WBGT, newRadius, newLineWidth, setdata_WBGT);
    updatePointRadiusInState(data_Hum, newRadius, newLineWidth, setdata_Hum);
    updatePointRadiusInState(data_Temp, newRadius, newLineWidth, setdata_Temp);

    updatePointRadiusInState(data_Wdir, newRadius, newLineWidth, setdata_Wdir);
    updatePointRadiusInState(data_Wspd, newRadius, newLineWidth, setdata_Wspd);

    updatePointRadiusInState(data_MWD, newRadius, newLineWidth, setdata_MWD);
    updatePointRadiusInState(data_WVHT, newRadius, newLineWidth, setdata_WVHT);
    updatePointRadiusInState(data_PD, newRadius, newLineWidth, setdata_PD);

    updatePointRadiusInState(data_Eau, newRadius, newLineWidth, setdata_Eau);

    updatePointRadiusInState(data_Pluie, newRadius, newLineWidth, setdata_Pluie);
    updatePointRadiusInState(data_Averse, newRadius, newLineWidth, setdata_Averse);
    updatePointRadiusInState(data_Soleil, newRadius, newLineWidth, setdata_Soleil);
    updatePointRadiusInState(data_Press, newRadius, newLineWidth, setdata_Press);

  }, [DateTimeRange, hours, spot]);





  const activeSpots = pointSource.data.features.filter(feature => feature.properties.active === "1");
  // const activeSpots = props.pointSource.data.features.filter(feature => feature.properties.active === "1");
  // console.log('activeSpots')
  // console.log(activeSpots)

  const generateSpotOptions = (source) => {
    return (
      <optgroup label={source}>
        {activeSpots
        // .filter(feature => feature.properties.source === source && feature.properties.active === "1")
        .filter(feature => feature.properties.source === source)
          .map(feature => (
            <option
              key={feature.properties.spot_id}
              value={feature.properties.spot_id}
              selected={spot === feature.properties.spot_id}
            >
              {feature.properties.spotname}
            </option>
          ))}
      </optgroup>
    );
  };

  // const generateSpotOptionsposition = (source) => {
  //   const userCoordinates = [Long, Lat]; // Utilisez les coordonnées de l'utilisateur
  
  //   return (
  //     <optgroup label={source}>
  //       {activeSpots
  //         .filter(feature => feature.properties.source === source)
  //         .map(feature => {
  //           const spotCoordinates = feature.geometry.coordinates;
  //           const distance = Math.sqrt(
  //             Math.pow(userCoordinates[0] - spotCoordinates[0], 2) +
  //             Math.pow(userCoordinates[1] - spotCoordinates[1], 2)
  //           );
  
  //           return {
  //             distance,
  //             feature,
  //           };
  //         })
  //         .sort((a, b) => a.distance - b.distance)
  //         .slice(0, 15) //limiter à 15 spots
  //         .map(({ feature }) => (
  //           <option
  //             key={feature.properties.spot_id}
  //             value={feature.properties.spot_id}
  //             selected={spot === feature.properties.spot_id}
  //           >
  //             {feature.properties.spotname}
  //           </option>
  //         ))}
  //     </optgroup>
  //   );
  // };

  const generateSpotOptionsposition = (source) => {
    const userCoordinates = [Long, Lat]; // Utilisez les coordonnées de l'utilisateur
  
    const includedSpotIds = new Set(); // Utiliser un ensemble pour stocker les identifiants des spots déjà inclus
  
    return (
      <optgroup label={source}>
        {activeSpots
          .filter(feature => feature.properties.source === source)
          .map(feature => {
            const spotCoordinates = feature.geometry.coordinates;
            const distance = Math.sqrt(
              Math.pow(userCoordinates[0] - spotCoordinates[0], 2) +
              Math.pow(userCoordinates[1] - spotCoordinates[1], 2)
            );
  
            return {
              distance,
              feature,
            };
          })
          .sort((a, b) => a.distance - b.distance)
          .slice(0, 15) //limiter à 15 spots
          .filter(({ feature }) => {
            if (!includedSpotIds.has(feature.properties.spot_id)) {
              includedSpotIds.add(feature.properties.spot_id); // Ajouter l'identifiant du spot à l'ensemble
              return true; // Retourner vrai si le spot n'a pas encore été inclus
            }
            return false; // Retourner faux si le spot a déjà été inclus
          })
          .map(({ feature }) => (
            <option
              key={feature.properties.spot_id}
              value={feature.properties.spot_id}
              selected={spot === feature.properties.spot_id}
            >
              {feature.properties.spotname}
            </option>
          ))}
      </optgroup>
    );
  };
  
  




  const [searchTerm, setSearchTerm] = useState('');

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePositionClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLong(position.coords.longitude);
          setLat(position.coords.latitude);
          
          selectstation(position.coords.longitude, position.coords.latitude); // fonction de rappel en props
          
          console.log('longitude', position.coords.longitude);
          console.log('latitude', position.coords.latitude);
  
          const userCoordinates = [position.coords.longitude, position.coords.latitude];
  
          const closestSpot = pointSource.data.features.reduce((closest, current) => {
          // const closestSpot = props.pointSource.data.features.reduce((closest, current) => {
            const spotCoordinates = current.geometry.coordinates;
            const distance = Math.sqrt(
              Math.pow(userCoordinates[0] - spotCoordinates[0], 2) +
              Math.pow(userCoordinates[1] - spotCoordinates[1], 2)
            );
            console.log('distance',distance)
  
            if (distance < closest.distance) {
              return { distance, feature: current };
            } else {
              return closest;
            }
          }, { distance: Infinity, feature: null });
  
          const closestSpotFeature = closestSpot.feature;
          // setSpotOnMp(closestSpotFeature.properties.spotname);
          setSpot(closestSpotFeature.properties.spot_id)
          console.log('closest spots:',closestSpotFeature.properties.spot_id)
        },
        (error) => {
          console.error(error);
        }
      );
    }


  };



  const filteredOptions = activeSpots
  .filter((feature) => {
 
    const normalizeString = (str) => {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    };
    const searchTermNormalized = normalizeString(searchTerm);
    const spotNameNormalized = feature.properties.spotname ? normalizeString(feature.properties.spotname) : 'null';
    // const spotNameNormalized = normalizeString(feature.properties.spotname);
    
    const searchTermRegex = new RegExp(`\\b${searchTermNormalized}`, 'i');
    
    return searchTermRegex.test(spotNameNormalized);
    
    
    // return searchTermRegex.test(feature.properties.spotname.toLowerCase());

    // return spotName.includes(searchTerm.toLowerCase());
    // return spotName.includes(searchTermRegex);
  })
  .map((feature) => (
    <option
      key={feature.properties.spot_id}
      value={feature.properties.spot_id}
      selected={spot === feature.properties.spot_id}
    >
      {feature.properties.spotname}
    </option>
  ));
  
  return (
    <Container fluid>
      <InstallBanner/>
      {/*<ImageUpload/>*/}
      <Row>
        <Col sm={12} lg={4}>  

        {/* <Row>
          <Select
            options={activeSpots.map(feature => ({
              value: feature.properties.spot_id,
              label: feature.properties.spotname
            }))}
            // value={activeSpots.find(feature => feature.properties.spot_id === spot)}
            value={activeSpots.find(feature => feature.properties.spot_id === spot)}
            onChange={option => setSpot(option.value)}
            isSearchable={true}
            // placeholder={activeSpots.find(feature => feature.properties.spot_id === spot)?.properties.spotname || "Sélectionner un spot"}
            // placeholder={"Sélectionner un spot"}
            // placeholder={null} // Ajoutez cette ligne pour supprimer le placeholder
            // // defaultValue={spot ? { value: spot.properties.spot_id, label: spot.properties.spotname } : null}
            // defaultValue={activeSpots.find(feature => feature.properties.spot_id === spot)?.properties.spotname || "Sélectionner un spot"}

          />
        </Row>    */}
 

        <Row>
    
        <Col>
          <Form.Label>Séléctioner un spot: &nbsp; {!showSearch &&<><BsSearch size={22} color='#656565' onClick={() => setShowSearch(!showSearch)}/></>}   {showSearch &&<><BsViewList size={22} color='#656565' onClick={() => setShowSearch(!showSearch)}/> </>} &nbsp; &nbsp; <BsGeoFill size={22} color='#0D6EFD' onClick={handlePositionClick}/>  &emsp; <Info ktskmh={ktskmh} setktskmh={setktskmh}/> </Form.Label>
          {/* <Form.Label>Séléctioner un spot: &nbsp; {!showSearch &&<><BsSearch size={22} color='#656565' onClick={() => setShowSearch(!showSearch)}/> mode liste</>}   {showSearch &&<><BsViewList size={22} color='#656565' onClick={() => setShowSearch(!showSearch)}/> mode recherche</>}</Form.Label> */}
          {/* <Form.Label>Séléctioner un spot: <BsGeoFill size={22} color='#0D6EFD' onClick={handleButtonClick}/> {!showSearch &&<BsSearch size={22} color='#656565' onClick={() => setShowSearch(!showSearch)}/>}   {showSearch &&<BsViewList size={22} color='#656565' onClick={() => setShowSearch(!showSearch)}/>}</Form.Label> */}
        </Col>
          </Row>
          {showSearch &&
          <Row>
          <Col>
            <Select
              options={activeSpots.map(feature => ({
                value: feature.properties.spot_id,
                label: feature.properties.spotname
              }))}
              // value={activeSpots.find(feature => feature.properties.spot_id === spot)}
              // value={activeSpots.find(feature => feature.properties.spot_id === spot)}
              value={spot}
              onChange={option => setSpot(option.value)}
              isSearchable={true}
              // placeholder={activeSpots.find(feature => feature.properties.spot_id === spot)?.properties.spotname || "Sélectionner un spot"}
              // placeholder={"Sélectionner un spot"}
              // placeholder={null} // Ajoutez cette ligne pour supprimer le placeholder
              // // defaultValue={spot ? { value: spot.properties.spot_id, label: spot.properties.spotname } : null}
              // defaultValue={activeSpots.find(feature => feature.properties.spot_id === spot)?.properties.spotname || "Sélectionner un spot"}

            />
            {/* <FormControl
                type="text"
                placeholder="Chercher un spot"
                value={searchTerm}
                onChange={handleInputChange}
              /> */}
              {/* <br></br> */}
            </Col>
        </Row>
        }
        {!showSearch &&
        <Row>

        <div>
        {/* <FormControl
            type="text"
            placeholder="Chercher un spot"
            value={searchTerm}
            onChange={handleInputChange}
          /> */}
        {/* <Form.Label>Séléctioner un spot:</Form.Label> */}
          {/* <FormControl
            type="text"
            placeholder="Chercher un spot"
            value={searchTerm}
            onChange={handleInputChange}
          /> */}

          <Form.Select size="lg" onChange={() => setSpot(event.target.value)} >
          {/* <Form.Select size="lg" onChange={handleChangeSpot}> */}
            <option>Séléctioner un spot</option>
              
              {searchTerm !== '' && 
              <optgroup label="Recherche">
                {filteredOptions}
              </optgroup>
              }
            {searchTerm === '' && (
              <>
                {generateSpotOptions('TrueWindSpeed')}
                {generateSpotOptionsposition('MeteoSwiss')}
                {generateSpotOptionsposition('METAR')}
                {generateSpotOptions('Vengeron')}
                {generateSpotOptions('Windspots')}
                {generateSpotOptionsposition('NOAA')}
                {generateSpotOptionsposition('Costal')}
              </>
            )}
            

          </Form.Select>
        </div>
        {/* </Col> */}
        </Row>
        }
        {/* <Row>
          <br></br>
        </Row>
        <Row>
          <iframe width="560" height="267" src="https://www.youtube.com/embed/QP4CdQ0J2Uk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen
          style={{
            border: "1px solid #CED4DA", //#0D6EFD",
            borderRadius: "10px", // Bordure avec un arrondi aux coins
            overflow: "hidden", // Empêche le contenu de déborder de la bordure
            margin: "0", // Supprime les marges par défaut
            padding: "0" // Supprime le padding par défaut
          }}></iframe>
        </Row> */}
        


        <Row>
          <Station selectstation={selectstation} spot={spot} pointSource={pointSource} timeAgo={moment(received_time[received_time.length - 1], 'DD/MM/YYYY, hh:mm:ss').fromNow()}/>
          {/* <Station selectstation={props.selectstation} spot={spot} pointSource={props.pointSource} timeAgo={moment(received_time[received_time.length - 1], 'DD/MM/YYYY, hh:mm:ss').fromNow()}/> */}
        </Row>




        <Row>
          {/* <Dirsvg received_time={received_time[received_time.length - 1]} received_at={received_at[received_at.length - 1]} received_date={received_date[received_date.length - 1]} Wind_ave={Wind_ave[Wind_ave.length - 1]} Wind_3sgust={Wind_3sgust[Wind_3sgust.length - 1]} Wind_3smin={Wind_3smin[Wind_3smin.length - 1]} Wind_stdev={Wind_stdev[Wind_stdev.length - 1]} Dir_ave={Dir_ave[Dir_ave.length - 1]} Dir_3sgust={Dir_3sgust[Dir_3sgust.length - 1]} Dir_stdev={Dir_stdev[Dir_stdev.length - 1]} gust_3stime={gust_3stime[gust_3stime.length - 1]} Pressure={Pressure[Pressure.length - 1]} Hum_SHT={Hum_SHT[Hum_SHT.length - 1]} TempC_DS={TempC_DS[TempC_DS.length - 1]} TempC_SHT={TempC_SHT[TempC_SHT.length - 1]} WTMP={WTMP[WTMP.length - 1]} Temp_Red={Temp_Red[Temp_Red.length - 1]} Temp_White={Temp_White[Temp_White.length - 1]} /> */}
          <Dirsvg 
          received_time={received_time.slice().reverse().find(value => value !== null && !isNaN(value))} 
          received_at={received_at.slice().reverse().find(value => value !== null && !isNaN(value))} 
          received_date={received_date.slice().reverse().find(value => value !== null && !isNaN(value))} 
          Wind_ave={Wind_ave.slice().reverse().find(value => value !== null && !isNaN(value))} 
          Wind_3sgust={Wind_3sgust.slice().reverse().find(value => value !== null && !isNaN(value))} 
          Wind_3smin={Wind_3smin.slice().reverse().find(value => value !== null && !isNaN(value))} 
          Wind_stdev={Wind_stdev.slice().reverse().find(value => value !== null && !isNaN(value))} 
          // Dir_ave={Dir_ave.slice().reverse().find(value => value !== null && !isNaN(value))} 
          Dir_ave={Dir_ave.slice().reverse().find(value => value !== null)} 
          Dir_3sgust={Dir_3sgust.slice().reverse().find(value => value !== null && !isNaN(value))} 
          Dir_stdev={Dir_stdev.slice().reverse().find(value => value !== null && !isNaN(value))} 
          gust_3stime={gust_3stime.slice().reverse().find(value => value !== null && !isNaN(value))} 
          Pressure={Pressure.slice().reverse().find(value => value !== null && !isNaN(value))} 
          Hum_SHT={Hum_SHT.slice().reverse().find(value => value !== null && !isNaN(value))} 
          TempC_DS={TempC_DS.slice().reverse().find(value => value !== null && !isNaN(value))} 
          TempC_SHT={TempC_SHT.slice().reverse().find(value => value !== null && !isNaN(value))} 
          WTMP={WTMP.slice().reverse().find(value => value !== null && !isNaN(value))} 
          Temp_Red={Temp_Red.slice().reverse().find(value => value !== null && !isNaN(value))} 
          Temp_White={Temp_White.slice().reverse().find(value => value !== null && !isNaN(value))}

          Type={Type.slice().reverse().find(value => value !== null && !isNaN(value))}
          T_min={T_min.slice().reverse().find(value => value !== null && !isNaN(value))}
          T_max={T_max.slice().reverse().find(value => value !== null && !isNaN(value))}
          Irradiation={Irradiation.slice().reverse().find(value => value !== null && !isNaN(value))}
          Irr_max={Irr_max.slice().reverse().find(value => value !== null && !isNaN(value))}

          Rain={totalRain}
          Rain_time={Rain_time.slice().reverse().find(value => value !== null && !isNaN(value))}
          Frost_alert={Frost_alert.slice().reverse().find(value => value !== null && !isNaN(value))}
          Heater_on={Heater_on.slice().reverse().find(value => value !== null && !isNaN(value))}
          RainIntensityCorrection={RainIntensityCorrection.slice().reverse().find(value => value !== null && !isNaN(value))}
          
          Rain_rate={Rain_rate.slice().reverse().find(value => value !== null && !isNaN(value))}
          
          DewP={DewP.slice().reverse().find(value => value !== null && !isNaN(value))}
          Visib={Visib.slice().reverse().find(value => value !== null && !isNaN(value))}
          vertVis={vertVis.slice().reverse().find(value => value !== null && !isNaN(value))}
          Snow={Snow.slice().reverse().find(value => value !== null && !isNaN(value))}



          WVHT={WVHT.slice().reverse().find(value => value !== null && !isNaN(value))} // - Hauteur significative des vagues ={en mètres)
          DPD={DPD.slice().reverse().find(value => value !== null && !isNaN(value))} // - Période de vague dominante ={en secondes)
          APD={APD.slice().reverse().find(value => value !== null && !isNaN(value))} // - Période moyenne de vague ={en secondes)
          MWD={MWD.slice().reverse().find(value => value !== null && !isNaN(value))} // - Direction moyenne des vagues ={en degrés)
      
          PTDY={PTDY.slice().reverse().find(value => value !== null && !isNaN(value))} // - Changement de pression en 3 heures ={en hPa)
      
      
      
          TIDE={TIDE.slice().reverse().find(value => value !== null && !isNaN(value))} // - Niveau de la marée
          hw={hw.slice().reverse().find(value => value !== null && !isNaN(value))} // - marée haute
          hw_time={hw_time.slice().reverse().find(value => value !== null && !isNaN(value))} // - heure marée haute
          lw={lw.slice().reverse().find(value => value !== null && !isNaN(value))} // - marée basse
          lw_time={lw_time.slice().reverse().find(value => value !== null && !isNaN(value))} // - heure marée basse
      
      

          hours={hours}



          ktskmh={ktskmh}
          />



        </Row>

        {(() => {
          //if (Dir_ave.some(value => value !== null && !isNaN(value))||Dir_3sgust.some(value => value !== null && !isNaN(value))||Wind_ave.some(value => value !== null && !isNaN(value))||Wind_3sgust.some(value => value !== null && !isNaN(value))) {
          if (0==1) {
              return (
              <div>
                <br></br>
                <Row>
                  <WindStat
                  received_at={received_at}
                  Wind_ave={Wind_ave}
                  Wind_3sgust={Wind_3sgust}
                  Wind_3smin={Wind_3smin}
                  Wind_stdev={Wind_stdev}
                  Dir_ave={Dir_ave}
                  Dir_3sgust={Dir_3sgust}
                  Dir_stdev={Dir_stdev}
                  gust_3stime={gust_3stime}
                  Pressure={Pressure}
                  Hum_SHT={Hum_SHT}
                  TempC_DS={TempC_DS}
                  TempC_SHT={TempC_SHT}
                  WTMP={WTMP}
                  Temp_Red={Temp_Red}
                  Temp_White={Temp_White}

                  Type={Type}
                  T_min={T_min}
                  T_max={T_max}
                  Irradiation={Irradiation}
                  Irr_max={Irr_max}
              
                  Rain={Rain}
                  Rain_time={Rain_time}
                  Frost_alert={Frost_alert}
                  Heater_on={Heater_on}
                  RainIntensityCorrection={RainIntensityCorrection}
                  />
                
                </Row>
              </div>
            )
          }
        })()}

        <Row>
          <Clock montre={0}/>
          <br></br>
        </Row>
      </Col>


      

      

      <Col sm={12} lg={8}>  
      <Row>
        <Col sm={12} lg={6}>
          {/* <DateTimeRangeFieldValue
            DateTimeRange={DateTimeRange} setDateTimeRange={setDateTimeRange}
            hours={hours} setHours={setHours}
          /> */}
          <DateTimeRangeFieldValue
            DateTimeRange={DateTimeRange} setDateTimeRange={setDateTimeRange}
            hours={hours} setHours={setHours}
          />
          <br></br>
        </Col>



        {/* <ToggleButtonGroup type="radio" name="options" defaultValue={6} value={hours} onChange={() => setHours(event.target.value)}> */}
        
        {hours>0 &&
          <ToggleButtonGroup type="radio" name="option" defaultValue={6}
            onChange={() => {
              setHours(event.target.value); 
              setDateTimeRange([dayjs().subtract(event.target.value, 'hours'), dayjs()])
              }
            }
          >
            
            <ToggleButton variant="outline-primary" id="tbg-radio-1" value={2}>
              2 h
            </ToggleButton>

            <ToggleButton variant="outline-primary" id="tbg-radio-2" value={3}>
              3 h
            </ToggleButton>
            
            <ToggleButton variant="outline-primary" id="tbg-radio-3" value={6}>
              6 h
            </ToggleButton>
            <ToggleButton variant="outline-primary" id="tbg-radio-4" value={12}>
              12 h
            </ToggleButton>
            <ToggleButton variant="outline-primary" id="tbg-radio-5" value={24}>
              24 h
            </ToggleButton>

          </ToggleButtonGroup>
        }

        {hours<=0 &&
          <ToggleButtonGroup type="radio" name="options" defaultValue={0}
            onChange={() => { if(event.target.value){
              setHours(event.target.value);
              setDateTimeRange([dayjs().subtract(event.target.value, 'hours'), dayjs()])
            } }}
          >
            
            <ToggleButton variant="outline-primary" id="tbg-radio-1" value={0}>
              Personnalisé
            </ToggleButton>

            <ToggleButton variant="outline-primary" id="tbg-radio-2" value={6}>
              2h - 3h - 6h - 12h - 24h
            </ToggleButton>
            
          </ToggleButtonGroup>
        }
        
      </Row>


      <Row>
      {(() => {
          if (Wind_ave.some(value => value !== null && !isNaN(value))||Wind_3sgust.some(value => value !== null && !isNaN(value))||Wind_3smin.some(value => value !== null && !isNaN(value))) {
            return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_Wspd} options={options_SPD}/>
                </div>
                <br></br>
              </div>
            )
          }
        })()}
        {(() => {
          if (Dir_ave.some(value => value !== null && !isNaN(value))||Dir_3sgust.some(value => value !== null && !isNaN(value))) {
            return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_Wdir} options={options_Dir}/>
                </div>
                <br></br>
              </div>
            )
          }
        })()}

        {(() => {
          if (Dir_ave.some(value => value !== null && !isNaN(value))||Dir_3sgust.some(value => value !== null && !isNaN(value))||Wind_ave.some(value => value !== null && !isNaN(value))||Wind_3sgust.some(value => value !== null && !isNaN(value))) {
            return (
              <div>
                <br></br>
                <Row>
                  <Col sm={12} lg={2}></Col>
                  <Col sm={12} lg={8}>
                    <WindStat
                    received_at={received_at}
                    Wind_ave={Wind_ave}
                    Wind_3sgust={Wind_3sgust}
                    Wind_3smin={Wind_3smin}
                    Wind_stdev={Wind_stdev}
                    Dir_ave={Dir_ave}
                    Dir_3sgust={Dir_3sgust}
                    Dir_stdev={Dir_stdev}
                    gust_3stime={gust_3stime}
                    TempC_DS={TempC_DS}
                    TempC_SHT={TempC_SHT}
                    WTMP={WTMP}
                    Temp_Red={Temp_Red}
                    Temp_White={Temp_White}
  
                    Type={Type}
                    T_min={T_min}
                    T_max={T_max}
                    Pressure={Pressure}
                    Hum_SHT={Hum_SHT}
                    Irradiation={Irradiation}
                    Irr_max={Irr_max}
                
                    Rain={Rain}
                    Rain_time={Rain_time}
                    Frost_alert={Frost_alert}
                    Heater_on={Heater_on}
                    RainIntensityCorrection={RainIntensityCorrection}
                    />
                  </Col>
                  <Col sm={12} lg={2}></Col>
                </Row>
              </div>
            )
          }
        })()}

        {(() => {
          if (WVHT.some(value => value !== null && !isNaN(value))) {
            return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_WVHT} options={options_WVHT}/>
                </div>
                <br></br>
              </div>
            )
          }
        })()}
        {(() => {
          if (DPD.some(value => value !== null && !isNaN(value))||APD.some(value => value !== null && !isNaN(value))) {
            return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_PD} options={options_PD}/>
                </div>
                <br></br>
              </div>
            )
          }
        })()}
        {(() => {
          if (MWD.some(value => value !== null && !isNaN(value))) {
            return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_MWD} options={options_MWD}/>
                </div>
                <br></br>
              </div>
            )
          }
        })()}
        
        {(() => {
          if (DPD.some(value => value !== null && !isNaN(value))&&APD.some(value => value !== null && !isNaN(value))) {
          // if (1==1) {
              return (
                <div>
                <br></br>
                <div style={{height:"60vh"}} >
                <Wave_line
                    // WVHT={WVHT.slice().reverse().find(value => value !== null && !isNaN(value))} 
                    DPD={DPD.slice().reverse().find(value => value !== null && !isNaN(value))} 
                    APD={APD.slice().reverse().find(value => value !== null && !isNaN(value))}                     
                    // MWD={MWD.slice().reverse().find(value => value !== null && !isNaN(value))} 
                    // WVHT={5} 
                    // DPD={12} 
                    // APD={3}                     
                    // MWD={120} 
                    />
                </div>
                <br></br>
              </div>
            )
          }
        })()}

        {(() => {
          if (Temp_White.some(value => value !== null && !isNaN(value))||WTMP.some(value => value !== null && !isNaN(value))||Temp_Red.some(value => value !== null && !isNaN(value))) {
            return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_Eau} options={options_EAU}/>
                </div>
                <br></br>
              </div>
            )
          }
        })()}

        {(() => {
          if (TempC_SHT.some(value => value !== null && !isNaN(value))) {
              return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_Temp} options={options_Temp}/>
                </div>
                <br></br>
              </div>

            )
          }
        })()}
        {(() => {
          // if (Hum_SHT.some(value => value !== null && !isNaN(value))) {
          if (Hum_SHT.some(value => value !== null && !isNaN(value))) {
            
            return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_Hum} options={options_Hum}/>
                </div>
                <br></br>
            </div>
            )
          }
        })()}
        {(() => {
          if (Pressure.some(value => value !== null && !isNaN(value))) {
            
            return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_Press} options={options_Press}/>
                </div>
                <br></br>
            </div>
            )
          }
        })()}
        {(() => {
          if (Irradiation.some(value => value !== null && !isNaN(value))) {
            
            return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_Soleil} options={options_Sol}/>
                </div>
                <br></br>
            </div>
            )
          }
        })()}
        {(() => {
          if (Rain.some(value => value !== null && !isNaN(value))) {
            
            return (
              <div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Bar data={data_Pluie} options={options_Pluie}/>
                </div>
                <br></br>
                <div style={{height:"60vh"}} >
                  <Line data={data_Averse} options={options_Averse}/>
                </div>
                <br></br>
            </div>
            )
          }
        })()}

      </Row>
    </Col>
      </Row>
        <br></br>
        <br></br>
        <br></br>
    </Container>
  );
};

export default Linechart;



