import React, { useRef, useState, useEffect } from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';

import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import SNG_LOGO from '../image/SNG_LOGO_RVB.jpg';


import ImageUpload from "./ImageUpload";

// newMap.addSource('points', {
//     type: 'geojson',
//     data: pointSource.data,
//     cluster: true, // enable clustering
//     clusterMaxZoom: 14, // max zoom level for clustering
//     clusterRadius: 50 // cluster radius, in pixels
// });

const MapTrack = ({
    centerLongitude,
    centerLatitude,
    zoom,
    pitch,
    bearing,
    exaggeration,
    defstyleUrl,

    onMove,
    changeURL,
    changeTimelaps,
    deftimelaps,
    images,
    tracker

}) => {



    const mapContainerRef = useRef(null);
    //const map = useRef(null);
    const [styleUrl, setStyleUrl] = useState(defstyleUrl);
    const [timelaps, setTimelaps] = useState(deftimelaps); 

    const [map, setMap] = useState(null);


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



    const createPopups = (images, map) => {

        map.on('load', function() {

        // Ajouter la source de données des popups à la carte
        map.addSource('popups', {
          type: 'geojson',
          data: images.data,
          cluster: true, // Activer le clustering
          clusterMaxZoom: 14, // Zoom maximum pour le clustering
          clusterRadius: 50 // Rayon de chaque cluster en pixels
        });
      
        // Ajouter une couche pour les clusters de popups
        map.addLayer({
          id: 'popup-clusters',
          type: 'circle',
          source: 'popups',
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': [
              'step',
              ['get', 'point_count'],
              '#51bbd6',
              20,'#f1f075',
              100,'#f28cb1',
              500, '#F23C4F'
            ],
            'circle-radius': [
              'step',
              ['get', 'point_count'],
              20, 
              20, 30, 
              100, 40,
              500, 50
            ]
          },
        });

        if(styleUrl=='https://tiles.stadiamaps.com/styles/alidade_smooth.json'||styleUrl=='https://tiles.stadiamaps.com/styles/alidade_smooth_dark.json'||styleUrl=='https://tiles.stadiamaps.com/styles/outdoors.json'){
            map.addLayer({
                id: 'cluster-count',
                type: 'symbol',
                source: 'popups',
                filter: ['has', 'point_count'],
                layout: {
                'text-field': '{point_count_abbreviated}',
                'text-font': ['Stadia Semibold'],
                // Stadia Regular
                // Stadia Italic
                // Stadia Bold
                // Stadia Semibold
                //'text-font': ['Noto Sans Arabic Light'],
                //'text-font': ['Swisstopo Standard'],
                'text-size': 12
                }
                });
        }

        
      

      
        // Ajouter une couche pour les popups individuelles
        map.addLayer({
          id: 'popup-unclustered',
          type: 'circle',
          source: 'popups',
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': '#FF0000',//'#11D2DA',//'#1156DA',//'#11b4da',
            'circle-radius': 12,
            'circle-stroke-width': 3,
            'circle-stroke-color': '#fff'
          }
        });
      
        // Inspecter un cluster lors d'un clic
        map.on('click', 'popup-clusters', function (e) {
          const features = map.queryRenderedFeatures(e.point, { layers: ['popup-clusters'] });
          const clusterId = features[0].properties.cluster_id;
          map.getSource('popups').getClusterExpansionZoom(clusterId, function (err, zoom) {
            if (err) return;
      
            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom
            });
          });
        });
      
        // Ouvrir une popup pour une popup individuelle lors d'un clic
        map.on('click', 'popup-unclustered', function (e) {
            const coordinates = e.features[0].geometry.coordinates.slice();
            const latitude = e.features[0].properties.latitude;
            const longitude = e.features[0].properties.longitude;
            const datetime = e.features[0].properties.datetime;
            const name = e.features[0].properties.end_device_ids;
            const ind = e.features[0].properties.ind;
            const batteryLevel = e.features[0].properties.batteryLevel;
            const altRef = e.features[0].properties.altRef;
            const cog = e.features[0].properties.cog;
            const hAcc = e.features[0].properties.hAcc;
            const hdop = e.features[0].properties.hdop;
            const navStat = e.features[0].properties.navStat;
            const numSvs = e.features[0].properties.numSvs;
            const sog = e.features[0].properties.sog;
            const vAcc = e.features[0].properties.vAcc;
            
            const popup = new maplibregl.Popup({closeButton: false, closeOnClick: true})
                // .setHTML(`
                //     <div>
                //       <p><strong>${name}</strong></p>
                //       <p>Date: ${datetime}</p>
                //       <br></br>
                      
                //     //   <p>Latitude: ${latitude}</p>
                //     //   <p>Longitude: ${longitude}</p>
                //     //   <br></br>
                      
                //       <p>Battery Level: ${batteryLevel/255}%</p>
                //       <br></br>
                      
                //       <p>Altitude Reference (m): ${altRef}</p>
                //     //   <p>Course Over Ground (°): ${cog}</p>
                //       <p>Horizontal Accuracy (m): ${hAcc}</p>
                //       <p>HDOP: ${hdop}</p>
                //       <br></br>

                //       <p>Navigation Status: ${navStat}</p>
                //       <p>Number of Satellites: ${numSvs}</p>
                //       <p>Speed Over Ground (m/s): ${sog}</p>
                //       <p>Vertical Accuracy: ${vAcc}</p>
                //     </div>
                // `)
                .setHTML(`
                    <div>
                      <p><strong>${name}</strong></p>
                      <p>Date: ${datetime}</p>

                      <p>Battery Level: ${Math.round(100*batteryLevel/255)}%</p>

                      <p>Horizontal Accuracy(m): ${hAcc}</p>
                      <p>Altitude Reference(m): ${altRef}</p>
                      <p>Vertical Accuracy(m): ${vAcc}</p>
                      <p>HDOP: ${hdop}</p>

                      <p>Navigation Status: ${navStat}</p>
                      <p>Number of Satellites: ${numSvs}</p>
                      <p>Speed Over Ground(m/s): ${sog}</p>
                    </div>
                `)
                .setLngLat(coordinates)
                .addTo(map);
                // Le HDOP est généralement exprimé sous forme de valeur numérique. Une valeur de HDOP inférieure à 1,0 est considérée comme excellente, tandis qu'une valeur supérieure à 5,0 peut indiquer une moins bonne précision.

            // map.flyTo({
            //     center: e.features[0].geometry.coordinates
            // });
            // popup.setOffset([0, popup._container.offsetHeight/2]);

            // var taille = Math.round(0.97*Math.min(window.innerWidth, window.innerHeight));
            var taille = Math.round(0.9*Math.min(window.innerWidth, window.innerHeight));
            popup.setMaxWidth(taille+'px');
            //.setOffset([0, -y]);
                
                
        });
        map.on('mouseenter', 'popup-clusters', function () {
            map.getCanvas().style.cursor = 'pointer';
            });
        map.on('mouseleave', 'popup-clusters', function () {
            map.getCanvas().style.cursor = '';
            });
        map.on('mouseenter', 'popup-unclustered', function () {
            map.getCanvas().style.cursor = 'pointer';
            });
        map.on('mouseleave', 'popup-unclustered', function () {
            map.getCanvas().style.cursor = '';
            });

        });
      
    };





    useEffect(() => {
        if (map) {


            console.log('map')
            // Update the map when the styleUrl changes
            //map.setStyle(styleUrl);
            
            // map.on('load', function() {
            if (images) {

                const existingSource = map.getSource('popups');
                console.log(existingSource)

                if (existingSource) {
                    existingSource.setData(images.data);
                    console.log(map.getSource('popups'))

                    console.log('point update')
                    console.log(images.data)
                } 
                // else {
                //     console.log('point create')
                //     createPopups(images, map);

                //     map.on('move', function () {
                //         onMove(
                //             map.getCenter().lng,
                //             map.getCenter().lat,
                //             map.getZoom(),
                //             timelaps);
                //     });
                // }
                



            } else {
                console.log('PAS createPopups')
            }

        //   });
        } else {
            console.log('PAS map')
            // console.log('images')
            // console.log(images)

          // Initialize the map with the initial values
          const newMap = new maplibregl.Map({
            container: mapContainerRef.current,
            style: styleUrl,
            center: [centerLongitude, centerLatitude],
            zoom: zoom,
            pitch: pitch,
            bearing: bearing,
            exaggeration: 8,
          });
      
          // Set the map state
          setMap(newMap);
            
        //   var nav = new maplibregl.NavigationControl();
        //   newMap.addControl(nav, 'top-right');

          newMap.addControl(new maplibregl.GeolocateControl({
              positionOptions: {
                  enableHighAccuracy: true
              },
              trackUserLocation: true,
          }));
      
          newMap.on('style.load', function() {
            
            if (images) {
                
                createPopups(images, newMap);
                //createmarker(images, newMap);

                newMap.on('move', function () {
                    onMove(
                        newMap.getCenter().lng,
                        newMap.getCenter().lat,
                        newMap.getZoom());
                    // onMove(
                    //     newMap.getCenter().lng,
                    //     newMap.getCenter().lat,
                    //     newMap.getZoom(),
                    //     timelaps);
                });

            }

          });
          
        }


    }, [images]);
      
      



  


    const handletimelapsChange = (value) => {
        setTimelaps(value);
        changeTimelaps(value);
    };

    return (
        <Container fluid>
            {/*<Row style={{ position: 'absolute', top: 10, left: 10, zIndex: 2 }}>
                <ToggleButtonGroup type="radio" name="options" defaultValue={styleUrl} onChange={() => handleStyleUrlChange(event.target.value)}>
                    <ToggleButton variant="primary" size="sm" id="tbg-radio-1" value={'https://api.maptiler.com/maps/basic-v2/style.json?key=eCz7oVi0RFfas03fdaFu'}>
                        OpenStreetMap
                    </ToggleButton>

                    <ToggleButton variant="primary" size="sm" id="tbg-radio-2" value={'https://vectortiles.geo.admin.ch/styles/ch.swisstopo.leichte-basiskarte.vt/style.json'}>
                        Swisstopo
                    </ToggleButton>

                    <ToggleButton variant="primary" size="sm" id="tbg-radio-3" value={'https://vectortiles.geo.admin.ch/styles/ch.swisstopo.leichte-basiskarte-imagery.vt/style.json'}>
                        SwissImage
                    </ToggleButton>

                    {/*<ToggleButton variant="primary" size="sm" id="tbg-radio-4" value={'https://api.maptiler.com/maps/outdoor-v2/style.json?key=eCz7oVi0RFfas03fdaFu'}>
    Outdoor
</ToggleButton>*/}{/*

                </ToggleButtonGroup>

            </Row>*/}


            <Row style={{ position: 'absolute', top: 10, left: 10, zIndex: 4 }}>
                

                <ToggleButtonGroup type="radio" name="tempsOptions" defaultValue={deftimelaps} onChange={(value) => handletimelapsChange(value)} >
                    <ToggleButton variant="primary" size="sm" id="tbg-radio-temps-1" value={0.25}>
                        15 min
                    </ToggleButton>
                    <ToggleButton variant="primary" size="sm" id="tbg-radio-temps-2" value={0.5}>
                        30 min
                    </ToggleButton>
                    <ToggleButton variant="primary" size="sm" id="tbg-radio-temps-3" value={1}>
                        1 h
                    </ToggleButton>
                    <ToggleButton variant="primary" size="sm" id="tbg-radio-temps-4" value={3}>
                        3 h
                    </ToggleButton>
                    <ToggleButton variant="primary" size="sm" id="tbg-radio-temps-5" value={6}>
                        6 h
                    </ToggleButton>
                    <ToggleButton variant="primary" size="sm" id="tbg-radio-temps-6" value={24}>
                        24 h
                    </ToggleButton>
                    <ToggleButton variant="primary" size="sm" id="tbg-radio-temps-7" value={168}>
                        1 sem
                    </ToggleButton>

                    {/*<ToggleButton variant="primary" size="sm" id="tbg-radio-4" value={'https://api.maptiler.com/maps/outdoor-v2/style.json?key=eCz7oVi0RFfas03fdaFu'}>
    Outdoor
</ToggleButton>*/}

                </ToggleButtonGroup>

            </Row>

            <Row style={{ position: 'absolute', top: 65, left: 10, zIndex: 3 }}>
                <ImageUpload/>
            </Row>



            <Row>

                <div
                    ref={mapContainerRef}
                    style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 55,
                        zIndex: 1
                    }}
                />
            </Row>

        </Container>


    );
};

export default MapTrack;

