// &nbsp; : Espace insécable.
// &ensp; : Espace de taille "n" (environ la moitié de &emsp;).
// &emsp; : Espace de taille "m".
// &thinsp; : Espace mince.


import { useState } from 'react';
// import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';

import Table from 'react-bootstrap/Table'
import Accordion from 'react-bootstrap/Accordion';
// import Button from 'react-bootstrap/Button';

import { BsInfoCircle } from "react-icons/bs";
import { CenterFocusStrong } from '@mui/icons-material';

import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Form from 'react-bootstrap/Form';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// import Container from 'react-bootstrap/Container';


const Info = ({ ktskmh, setktskmh }) => {


  const [ours, setours] = useState(6); // état initial de 6 heures

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {/* <Button variant="outline-info" onClick={handleShow} > <BsInfoCircle size={28}/> </Button> */}
      <BsInfoCircle size={28} color='#0D6EFD' onClick={handleShow}/>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Légendes</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>


{/* 
        {/* <Col sm={12} lg={8}>   *}
        <Col>  

          <Row>

            {/* <ToggleButtonGroup type="radio" name="ktskmhoptions" value={ktskmh} onChange={() => { setktskmh(event.target.value); } } > *}
            <ToggleButtonGroup
              type="radio"
              name="ktskmh_options"
              value={ktskmh}
              onChange={(value) => setktskmh(value)}
            >
                <ToggleButton variant="outline-primary" id="ktskmhtbg-radio-1" value={1}>
                knots
                </ToggleButton>

                <ToggleButton variant="outline-primary" id="ktskmhtbg-radio-2" value={1.852}>
                km/h
                </ToggleButton>

                <ToggleButton variant="outline-primary" id="ktskmhtbg-radio-3" value={1.852/3.6}>
                m/s
                </ToggleButton>
                
            </ToggleButtonGroup>
            
          </Row>
        </Col>



            <br></br>
            <br></br>
            <br></br>
            <br></br> */}





            <Table bordered>
                <thead>
                    <tr>
                    {/* <th>Couleur</th> */}
                    <th>Variable</th>
                    {/* <th colSpan={2}>Description</th> */}
                    <th>Description</th>
                    <th>Unité</th>
                    </tr>
                </thead>
                <tbody>

                    <tr>
                    <td  colSpan={2} style={{ color: 'rgb(53, 162, 235)', textAlign: 'center' }}><strong>Vent<sub>moyen</sub></strong></td>
                    <td>
                      <Form.Select size="sm" value={ktskmh} onChange={(event) => setktskmh(event.target.value)} >
                        <option value={1}>knots</option>
                        <option value={1.852}>km/h</option>
                        <option value={1.852/3.6}>m/s</option>
                      </Form.Select>
                    </td>
                    </tr>
                    
                    <tr>
                    <td  colSpan={2} style={{ color: 'rgb(255, 99, 132)', textAlign: 'center' }}><strong>Rafale</strong></td>
                    <td>
                      <Form.Select size="sm" value={ktskmh} onChange={(event) => setktskmh(event.target.value)} >
                        <option value={1}>knots</option>
                        <option value={1.852}>km/h</option>
                        <option value={1.852/3.6}>m/s</option>
                      </Form.Select>
                    </td>
                    </tr>
                    <tr>
                    <td  colSpan={2} style={{ color: 'rgb(255, 216, 123)', textAlign: 'center' }}><strong>Vent<sub>minimum</sub></strong></td>
                    <td>
                      <Form.Select size="sm" value={ktskmh} onChange={(event) => setktskmh(event.target.value)} >
                        <option value={1}>knots</option>
                        <option value={1.852}>km/h</option>
                        <option value={1.852/3.6}>m/s</option>
                      </Form.Select>
                    </td>
                    </tr>

                    {/* <tr>
                    <td style={{ color: 'rgb(255, 216, 123)', textAlign: 'center' }}><strong>Vent<sub>minimum</sub></strong></td>
                    <td colSpan={2}>
                      <ToggleButtonGroup
                        type="radio"
                        name="ktskmhoptions"
                        value={ktskmh}
                        onChange={(value) => setktskmh(value)}
                      >
                          <ToggleButton variant="outline-primary" id="ktskmhtbg-radio-1" value={1}>
                          knots
                          </ToggleButton>

                          <ToggleButton variant="outline-primary" id="ktskmhtbg-radio-2" value={1.852}>
                          km/h
                          </ToggleButton>

                          <ToggleButton variant="outline-primary" id="ktskmhtbg-radio-3" value={1.852/3.6}>
                          m/s
                          </ToggleButton>
                          
                      </ToggleButtonGroup>
                    </td>
                    </tr> */}

                    {/* <tr>
                    <td  colSpan={3} style={{ background: `linear-gradient(to right,
                        rgb(255, 255, 255) 0%, 
                        rgb(155, 255, 255) 8%, 
                        rgb(102, 247, 241) 16%, 
                        rgb(3, 255, 0) 24%, 
                        rgb(255, 239, 2) 32%, 
                        rgb(255, 51, 44) 40%, 
                        rgb(255, 8, 199) 60%, 
                        rgb(255, 0, 255) 68%, 
                        rgb(150, 50, 255) 76%, 
                        rgb(60, 60, 255) 84%, 
                        rgb(0, 0, 255) 92%, 
                        rgb(0, 0, 0) 100%
                      )`
                    }}>0&ensp;&thinsp;&thinsp;&thinsp;2&ensp;&thinsp;&thinsp;&thinsp;8&ensp;&thinsp;&thinsp;&thinsp;15&ensp;&thinsp;&thinsp;&thinsp;20&ensp;&thinsp;&thinsp;&thinsp;25&ensp;&thinsp;&thinsp;&thinsp;30&ensp;&thinsp;&thinsp;&thinsp;35&ensp;&thinsp;&thinsp;&thinsp;40&ensp;&thinsp;&thinsp;&thinsp;45&ensp;&thinsp;&thinsp;&thinsp;50&ensp;&thinsp;&thinsp;&thinsp;55</td>
                    </tr>
                    <tr><td  colSpan={3}></td></tr> */}


                    <tr>
                    <td  colSpan={3} style={{ background: `linear-gradient(to right,
                        rgb(255, 255, 255) 0%, 
                        rgb(155, 255, 255) 8%, 
                        rgb(102, 247, 241) 16%, 
                        rgb(3, 255, 0) 24%, 
                        rgb(255, 239, 2) 32%, 
                        rgb(255, 51, 44) 40%, 
                        rgb(255, 8, 199) 60%, 
                        rgb(255, 0, 255) 68%, 
                        rgb(150, 50, 255) 76%, 
                        rgb(60, 60, 255) 84%, 
                        rgb(0, 0, 255) 92%, 
                        rgb(0, 0, 0) 100%
                      )`
                    }}></td>
                    </tr>
                    <tr>
                    <td colSpan={3} style={{textAlign:'center', fontSize: 9 }}>0&thinsp;&thinsp;&thinsp;&ensp;|&ensp;&thinsp;&thinsp;&thinsp;2&thinsp;&thinsp;&thinsp;&ensp;|&ensp;&thinsp;&thinsp;&thinsp;8&thinsp;&thinsp;&thinsp;&ensp;|&ensp;&thinsp;&thinsp;&thinsp;15&thinsp;&thinsp;&thinsp;&ensp;|&ensp;&thinsp;&thinsp;&thinsp;20&thinsp;&thinsp;&thinsp;&ensp;|&ensp;&thinsp;&thinsp;&thinsp;25&thinsp;&thinsp;&thinsp;&ensp;|&ensp;&thinsp;&thinsp;&thinsp;30&thinsp;&thinsp;&thinsp;&ensp;|&ensp;&thinsp;&thinsp;&thinsp;35&thinsp;&thinsp;&thinsp;&ensp;|&ensp;&thinsp;&thinsp;&thinsp;40&thinsp;&thinsp;&thinsp;&ensp;|&ensp;&thinsp;&thinsp;&thinsp;45&thinsp;&thinsp;&thinsp;&ensp;|&ensp;&thinsp;&thinsp;&thinsp;50&thinsp;&thinsp;&thinsp;&ensp;|&ensp;&thinsp;&thinsp;&thinsp;55</td>
                    </tr>
                    <tr><td  colSpan={3}></td></tr>


{/* 

                    <tr>
                    <td  colSpan={3} style={{ background: `linear-gradient(to right,
                        rgb(255, 255, 255) 0%, 
                        rgb(155, 255, 255) 8%, 
                        rgb(102, 247, 241) 16%, 
                        rgb(3, 255, 0) 24%, 
                        rgb(255, 239, 2) 32%, 
                        rgb(255, 51, 44) 40%, 
                        rgb(255, 8, 199) 60%, 
                        rgb(255, 0, 255) 68%, 
                        rgb(150, 50, 255) 76%, 
                        rgb(60, 60, 255) 84%, 
                        rgb(0, 0, 255) 92%, 
                        rgb(0, 0, 0) 100%
                      )`
                    }}></td>
                    </tr>
                    <tr>
                    <td colSpan={3} style={{fontSize: 9 }}>

                    <Table bordered>
                      <tbody>
                        <tr style={{ background: `linear-gradient(to right,
                        rgb(255, 255, 255) 0%, 
                        rgb(155, 255, 255) 8%, 
                        rgb(102, 247, 241) 16%, 
                        rgb(3, 255, 0) 24%, 
                        rgb(255, 239, 2) 32%, 
                        rgb(255, 51, 44) 40%, 
                        rgb(255, 8, 199) 60%, 
                        rgb(255, 0, 255) 68%, 
                        rgb(150, 50, 255) 76%, 
                        rgb(60, 60, 255) 84%, 
                        rgb(0, 0, 255) 92%, 
                        rgb(0, 0, 0) 100%
                      )`
                    }}>
                        <td>0</td>
                        <td>2</td>
                        <td>8</td>
                        <td>15</td>
                        <td>20</td>
                        <td>25</td>
                        <td>30</td>
                        <td>35</td>
                        <td>40</td>
                        <td>45</td>
                        <td>50</td>
                        <td>55</td>
                        </tr>
                      </tbody>
                    </Table>
                      </td>
                    </tr> */}



                    <tr>
                    <td  colSpan={2} style={{ color: 'rgb(255, 158, 64)', textAlign: 'center' }}><strong>Hauteur significative des vagues</strong></td>
                    <td style={{ verticalAlign:'middle', textAlign: 'center' }}>m</td>
                    </tr>
                    <tr>
                      <td  colSpan={3} style={{ background: `linear-gradient(to right,
                          rgb(255, 255, 255) 0%, 
                          rgb(155, 255, 255) 8%, 
                          rgb(102, 247, 241) 16%, 
                          rgb(3, 255, 0) 24%, 
                          rgb(255, 239, 2) 32%, 
                          rgb(255, 51, 44) 40%, 
                          rgb(255, 8, 199) 60%, 
                          rgb(255, 0, 255) 68%, 
                          rgb(150, 50, 255) 76%, 
                          rgb(60, 60, 255) 84%, 
                          rgb(0, 0, 255) 92%, 
                          rgb(0, 0, 0) 100%
                        )`
                      }}></td>
                    </tr>
                    <tr>
                    <td colSpan={3} style={{textAlign:'center', fontSize: 9 }}>0&emsp;|&emsp;0.25&emsp;|&emsp;0.5&emsp;|&emsp;1&emsp;|&emsp;1.5&emsp;|&emsp;2&emsp;|&emsp;3&emsp;|&emsp;4&emsp;|&emsp;5&emsp;|&emsp;7&emsp;|&emsp;10&emsp;|&emsp;15</td>
                    </tr>
                    <tr><td  colSpan={3}></td></tr>



                    
                    
                    <tr>
                    <td  colSpan={2} style={{ color: 'rgb(154, 102, 255)', textAlign: 'center' }}><strong>Période des vagues dominantes</strong></td>
                    <td style={{ verticalAlign:'middle', textAlign: 'center' }}>s</td>
                    </tr>
                    <tr>
                    <td  colSpan={2} style={{ color: 'rgb(76, 192, 192)', textAlign: 'center' }}><strong>Période moyenne des vagues</strong></td>
                    <td style={{ verticalAlign:'middle', textAlign: 'center' }}>s</td>
                    </tr>

                    <tr>
                      <td  colSpan={3} style={{ background: `linear-gradient(to right,
                          rgb(255, 255, 255) 0%, 
                          rgb(155, 255, 255) 8%, 
                          rgb(102, 247, 241) 16%, 
                          rgb(3, 255, 0) 24%, 
                          rgb(255, 239, 2) 32%, 
                          rgb(255, 51, 44) 40%, 
                          rgb(255, 8, 199) 60%, 
                          rgb(255, 0, 255) 68%, 
                          rgb(150, 50, 255) 76%, 
                          rgb(60, 60, 255) 84%, 
                          rgb(0, 0, 255) 92%, 
                          rgb(0, 0, 0) 100%
                        )`
                      }}></td>
                    </tr>
                    <tr>
                    <td colSpan={3} style={{textAlign:'center', fontSize: 9 }}>0&emsp;&thinsp;|&emsp;2&emsp;&thinsp;|&emsp;3&emsp;&thinsp;|&emsp;5&emsp;&thinsp;|&emsp;6&emsp;&thinsp;|&emsp;8&emsp;&thinsp;|&emsp;10&emsp;&thinsp;|&emsp;13&emsp;&thinsp;|&emsp;17&emsp;|&emsp;20&emsp;&thinsp;|&emsp;25&emsp;|&emsp;30</td>
                    </tr>
                    <tr><td  colSpan={3}></td></tr>



                    <tr>
                    <td  colSpan={2} style={{ color: 'rgb(255, 99, 132)', textAlign: 'center' }}><strong>Température de l'Air</strong></td>
                    <td style={{ verticalAlign:'middle', textAlign: 'center' }}>°C</td>
                    </tr>
                    <tr>
                      <td  colSpan={3} style={{ background: `linear-gradient(to right,
                          rgb(50, 0, 60) 0%,
                          rgb(0, 0, 60) 7%,
                          rgb(0, 0, 255) 14%,
                          rgb(0, 122, 255) 21%,
                          rgb(0, 255, 255) 28%,
                          
                          rgb(100, 255, 190) 35%,
                          rgb(190, 255, 100) 42%,
                          
                          rgb(255, 255, 0) 49%,
                          
                          rgb(0, 255, 0) 56%,
                          
                          rgb(255, 255, 0) 63%,
                          
                          rgb(255, 122, 0) 70%,
                          
                          rgb(255, 0, 0) 77%,
                          rgb(255, 0, 255) 84%,
                          rgb(125, 0, 255) 91%,
                          rgb(40, 0, 0) 100%
                        )`
                      }}></td>
                    </tr>
                    <tr>
                    <td colSpan={3} style={{textAlign:'center', fontSize: 9 }}>-50&ensp;|&ensp;-25&ensp;|&ensp;-10&ensp;|&ensp;-5&ensp;|&ensp;0&ensp;|&ensp;5&ensp;|&ensp;10&ensp;|&ensp;15&ensp;|&ensp;20&ensp;|&ensp;25&ensp;|&ensp;30&ensp;|&ensp;35&ensp;|&ensp;40&ensp;|&ensp;45&ensp;|&ensp;55</td>
                    </tr>
                    <tr><td  colSpan={3}></td></tr>








                    <tr>
                    <td style={{ color: 'rgba(53, 162, 235)', textAlign: 'center', verticalAlign: 'middle' }}><strong>HR</strong></td>
                    <td colSpan={2}>humidité relative %<br/> <i>HR = <sup> P<sub>vap</sub> </sup>/<sub> P<sub>sat</sub>(T) </sub>&sdot; 100</i></td> 
                    {/* &phi; */}
                    </tr>
                    <tr>
                      <td  colSpan={3} style={{ background: `linear-gradient(to right,
                          rgb(255, 255, 255) 0%, 
                          rgb(0, 0, 255) 100%
                        )`
                      }}></td>
                    </tr>
                    <tr>
                    <td colSpan={3} style={{textAlign:'center', fontSize: 9 }}>0&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;100</td>
                    </tr>
                    <tr><td  colSpan={3}></td></tr>




                    <tr>
                    <td colSpan={2} style={{ color: 'rgb(151, 151, 151)', textAlign: 'center', verticalAlign: 'middle' }}><strong>Pression QNH</strong></td>
                    <td style={{ verticalAlign:'middle', textAlign: 'center' }}>hPa</td>
                    </tr>
                    <tr>
                      <td  colSpan={3} style={{ background: `linear-gradient(to right,
                          rgb(100, 0, 155) 0%, 
                          rgb(0, 0, 255) 25%,
                          rgb(0, 255, 0) 50%,
                          rgb(255, 0, 0) 75%,
                          rgb(155, 0, 100) 100%
                        )`
                      }}></td>
                    </tr>
                    <tr>
                    <td colSpan={3} style={{textAlign:'center', fontSize: 9 }}>986&emsp;&emsp;&emsp;|&emsp;&emsp;&emsp;1005&emsp;&emsp;&emsp;|&emsp;&emsp;&emsp;1018&emsp;&emsp;&emsp;|&emsp;&emsp;&emsp;1028&emsp;&emsp;&emsp;|&emsp;&emsp;&emsp;1037</td>
                    </tr>
                    <tr><td  colSpan={3}></td></tr>




                </tbody>
            </Table>




            {/* <br></br>
            <br></br>
            <br></br>
            <br></br>



            <strong>Classification des niveaux de métabolisme énergétique selon l'ISO 8996</strong>
            {/* <Accordion defaultActiveKey="0"> *}
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                    <strong>#0&nbsp;&nbsp;&nbsp;</strong>Repos<br/> &rarr; 115W  (100 à 125)
                    </Accordion.Header>
                    <Accordion.Body>
                    Repos, position assise confortable
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                    <strong>#1&nbsp;&nbsp;&nbsp;</strong>Métabolisme énergétique faible<br/> &rarr; 180W (125W à 235W) 
                    </Accordion.Header>
                    <Accordion.Body>
                    Travail manuel léger (écriture, frappe à la machine, dessin, couture, comptabilité); travail des mains et des bras (petits outils d'établi, inspection, assemblage ou triage de matériaux légers); travail des bras et des jambes (conduite de véhicule dans des conditions normales, manœuvre d'un interrupteur à pied ou d'une pédale).
                    <br/><br/>
                    Debout: perceuse (petites pièces); fraiseuse (petites pieces); bobinage;
                    enroulement de petites armatures; usinage avec outlis de faible puissance;
                    marche occasionnelle sur une surface plane (vitesse jusqu'à 2,5 km/h).
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                    <strong>#2&nbsp;&nbsp;&nbsp;</strong>Métabolisme énergétique modéré<br/> &rarr; 300W (235W à 360W)
                    </Accordion.Header>
                    <Accordion.Body>
                    Travail soutenu des mains et des bras (cloutage, limage); travail des bras et des jambes (manœuvre sur chantiers de camions, tracteurs ou engins); travall des bras et du tronc (travail au marteau pneumatique, accouplement de véhicules, plâtrage, manipulation intermittente de matériaux modérément lourds, sarclage, binage, cueillette de fruits ou de légumes; poussée ou traction de charrettes légères ou de brouettes; marche à une vitesse de 2,5 à 5,5 km/h sur une surface plane; forgeage).
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>
                    <strong>#3&nbsp;&nbsp;&nbsp;</strong>Métabolisme énergétique élevé<br/> &rarr; 415W (360W à 465W)
                    </Accordion.Header>
                    <Accordion.Body>
                    Travail intense des bras et du tronc; transport de matériaux lourds; pelletage; travail au marteau; sciage, planage ou ciselage de bois dur:
fauchage manuel; excavation; marche à une vitesse de 5,5 à 7 km/h sur une surface plane.<br/><br/>
Poussée ou traction de charrettes à bras ou de brouettes lourdement chargées; enlèvement de copeaux de pièces moulées; pose de blocs de béton.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>
                    <strong>#4&nbsp;&nbsp;&nbsp;</strong>Métabolisme énergétique très élevé<br/> &rarr; 520W (&gt;465W)
                    </Accordion.Header>
                    <Accordion.Body>
                    Activité très intense à allure rapide proche du maximum; travail à la hache; pelletage ou excavation à un rythme intensif; action de monter des escaliers, une rampe ou une échelle; marche rapide à petits pas; course sur une surface plane; marche à une vitesse supérieure à 7 km/h sur une surface plane.
                    </Accordion.Body>
                </Accordion.Item>
                
            </Accordion> */}





        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Info;






                
                


//                 'QNH'], null],
//                 'paint': {
//                     ...CircleLayerProperties.paint,
//                     'circle-color': [
//                         'interpolate',
//                         ['linear'],
//                         ['to-number', ['get', 'QNH']],
//                         986, 'rgb(100, 0, 155)',
//                         1005, 'rgb(0, 0, 255)',
//                         1018, 'rgb(0, 255, 0)',
//                         1028, 'rgb(255, 0, 0)',
//                         1037, 'rgb(155, 0, 100)',
        
//                     ],  




//                     'Irradiation'], null],
//                     'paint': {
//                         ...CircleLayerProperties.paint,
//                         'circle-color': [
//                             'interpolate',
//                             ['linear'],
//                             ['to-number', ['get', 'Irradiation']],
//                             0, 'rgb(0, 0, 155)',
//                             10000, 'rgb(255, 255, 0)',
            
//                         ],







//                         'WTMP'], null],
//                         'paint': {
//                             ...CircleLayerProperties.paint,
//                             'circle-color': [
//                                 'interpolate',
//                                 ['linear'],
//                                 ['to-number', ['get', 'WTMP']],
//                                 0, 'rgb(0, 255, 255)',
//                                 10, 'rgb(0, 0, 255)',
//                                 20, 'rgb(0, 255, 0)',
//                                 30, 'rgb(255, 0, 0)',
//                                 40, 'rgb(155, 0, 100)',
                
//                             ],  





//                             'WVHT'], null],
//                             'paint': {
//                                 ...CircleLayerProperties.paint,
//                                 'circle-color': [
//                                     'interpolate',
//                                     ['linear'],
//                                     ['to-number', ['get', 'WVHT']],
//                                     0, 'rgb(255, 255, 255)',
//                                     0.25, 'rgb(155, 255, 255)',
//                                     0.5, 'rgb(102, 247, 241)',
//                                     1, 'rgb(3, 255, 0)',
//                                     1.5, 'rgb(255, 239, 2)',
//                                     2, 'rgb(255, 51, 44)',
//                                     3, 'rgb(255, 8, 199)',
//                                     4, 'rgb(255, 0, 255)',
//                                     5, 'rgb(150, 50, 255)',
//                                     7, 'rgb(60, 60, 255)',
//                                     10, 'rgb(0, 0, 255)',
//                                     15, 'rgb(0, 0, 0)',
//                                 ], 





//                             'TIDE'], null],
//                             'paint': {
//                                 ...CircleLayerProperties.paint,
//                                 'circle-color': [
//                                     'interpolate',
//                                     ['linear'],
//                                     ['to-number', ['get', 'TIDE']],
//                                     -800, 'rgb(100, 0, 155)',
//                                     -200, 'rgb(0, 0, 255)',
//                                     0, 'rgb(0, 255, 0)',
//                                     200, 'rgb(255, 0, 0)',
//                                     800, 'rgb(155, 0, 100)',
//                                 ],   