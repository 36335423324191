import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";


function ProductDetails() {
    return (
      <div>

      </div>
    );
  }

  export default ProductDetails;
  