import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

//import icon from '../img/icon-512x512.png'
//import iconHD from '../img/maskable_icon.png'
import icon from '../img/icon-1080x1080.png'

function InstallPrompt() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleInstallPrompt);
    return () => {
      window.removeEventListener('beforeinstallprompt', handleInstallPrompt);
    };
  }, []);

  const handleInstallPrompt = (e) => {
    e.preventDefault();
    setDeferredPrompt(e);
    setShowPrompt(true);
  };

  const handleInstall = () => {
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        //console.log('User installed the app');
      }
      setDeferredPrompt(null);
      setShowPrompt(false);
    });
  };

  const handleClose = () => {
    setShowPrompt(false);
  };

  if (showPrompt) {
    return (
        {/*<Alert variant="warning" onClose={handleClose} dismissible>
            <Alert.Heading>Installe l'App TWS! <img src={icon} style={{maxHeight: "8vh", maxWidth: "8vh", margin: "0 auto", textAlign:"right"}}/></Alert.Heading>

            <div className="install-prompt">
                <p>
                    TrueWindSpeed ne prend aucune place sur ton ordi car c'est une Webapp. Clic pour l'installer et l'avoir toujours avec toi! TWS, l'application des passionnés de
                </p>
                <div className="d-grid gap-3" >
                    <Button variant="outline-primary" onClick={handleInstall} size="lg">
                      Clic pour installer L'App !
                    </Button>
                </div>
            
            </div>
        </Alert>*/},
        <Alert variant="success" onClose={handleClose} dismissible>
            <Alert.Heading>Installe l'App TWS! <img src={icon} style={{maxHeight: "8vh", maxWidth: "8vh", margin: "0 auto", textAlign:"right"}}/></Alert.Heading>

            <div className="install-prompt">
                <p>
                    TrueWindSpeed ne prend aucune place sur ton ordi car c'est une Webapp. Clic pour l'installer et l'avoir toujours avec toi! TWS, l'application des passionnés de
                </p>
                <div className="d-grid gap-3" >
                    <Button variant="outline-success" onClick={handleInstall} size="lg">
                      Clic pour installer L'App !
                    </Button>
                </div>
            
            </div>
        </Alert>

        
    );
  } else {
    return null;
  }
}

export default InstallPrompt;


/*import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';

function InstallBanner() {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const isInstalled = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true;
    setShowBanner(!isInstalled);
  }, []);

  const handleInstallClick = () => {
    window.prompt("Pour ajouter cette application à votre écran d'accueil, appuyez sur le bouton 'Ajouter' ci-dessous.");
  };

  return (
    showBanner && (
      <div className="p-3 bg-primary fixed-bottom text-center">
        <p className="text-light">Ajoutez cette application à votre écran d'accueil pour y accéder plus facilement !</p>
        <Button variant="light" onClick={handleInstallClick}>Installer</Button>
      </div>
    )
  );
}

export default InstallBanner;*/
