import React, { useState, useEffect } from 'react';

function Mongps() {
  const [show, setShow] = useState(false);

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  
  const [accuracy, setAccuracy] = useState(null);

  const [altitude, setAltitude] = useState(null);
  const [altitudeAccuracy, setAltitudeAccuracy] = useState(null);
  const [timestamp, setTimestamp] = useState(null);


  const [speed, setSpeed] = useState(null);
  const [heading, setHeading] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition((position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
        setAccuracy(position.coords.accuracy);
        
        setAltitude(position.coords.altitude);
        setAltitudeAccuracy(position.coords.altitudeAccuracy);
        setTimestamp(position.timestamp);

        setSpeed(position.coords.speed);
        //setHeading(position.coords.heading);
      });
    } else {
      setShow(true);
      alert("La géolocalisation n'est pas prise en charge par ce navigateur.");
    }

      if (window.DeviceOrientationEvent) {
        window.addEventListener('deviceorientation', function(e) {
          var angle = e.webkitCompassHeading || Math.abs(e.alpha - 360);
          console.log( e.webkitCompassHeading );
          setHeading(angle);

      }, false);
    } else {
      console.log("L'API DeviceOrientationEvent n'est pas supportée sur cet appareil.");
    }
    
  //}, []);
  }, 1000);

  return (
    <div>
      <p>Latitude: {latitude}</p>
      <p>Longitude: {longitude}</p>

      <p>accuracy: {accuracy}</p>
      <p>altitude: {altitude}</p>
      <p>altitudeAccuracy: {altitudeAccuracy}</p>
      <p>timestamp: {timestamp}</p>

      <p>Vitesse: {speed}</p>
      <p>Orientation: {heading}</p>
      {speed && <p>Vitesse: {speed}</p>}
      {heading && <p>Orientation: {heading}</p>}

      
        {show &&
              <Alert variant="danger" onClose={() => setShow(false)} dismissible  style={{transform: 'translate(-50, 0)'}}>
              <Alert.Heading>Oh non! La géolocalisation a échoué... pas de panique</Alert.Heading>
              <p>
              <br></br>
              <br></br>
      
              Veuillez autoriser l'accès à votre position dans les <strong>"Réglages Systèmes"</strong> de votre appareil. 
              <br></br>
              <br></br><strong>iOS</strong>: <strong>"Réglages"</strong>&rarr;<strong>"Confidentialité"</strong>&rarr;<strong>"Services de localisation"</strong> et en autorisez l'accès pour le navigateur web que vous utilisez. 
              <br></br>
              <br></br><strong>Android</strong>: <strong>"Paramètres"</strong>&rarr;<strong>"Applications et notifications"</strong>. Trouvez le navigateur web que vous utilisez, puis &rarr;<strong>"Autorisations"</strong>, autoriser l'accès à votre position en activant l'option <strong>"Localisation"</strong>.
              </p>
            </Alert>
            }
      </div>
  );
}

export default Mongps;
