import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';


import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import tudor from '../img/tudor.jpg';


function Sponso (props) {




  return (
    <Container fluid>
        
            <Row>
                <Col></Col>
                <Col>
                    <br></br>
                    <Alert className="text-center" variant='warning' style={{ width: '18rem' }}>
                        <h2>Gold Sponsor</h2>
                    </Alert>
                    <Card className="text-center" >
                            <Card.Header bg='warning'>Featured</Card.Header>
                            <Card.Body>
                                <Card.Title>Special title treatment</Card.Title>
                                <Card.Text>
                                    With supporting text below as a natural lead-in to additional content.
                                </Card.Text>
                                <Button variant="primary">Go somewhere</Button>
                            </Card.Body>
                            <Card.Img variant="bottom" src={tudor} />
                            <Card.Footer className="text-muted">2 days ago</Card.Footer>
                    </Card>
                    
                </Col>
                <Col></Col>
            </Row>
    </Container>
  );
}


export default Sponso;

