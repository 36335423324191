import React, { useState, useEffect, useRef } from 'react';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';


import * as nsfwjs from 'nsfwjs';



import * as tf from '@tensorflow/tfjs';
import * as mobilenet from '@tensorflow-models/mobilenet';
import Resizer from 'react-image-file-resizer';



import ProgressBar from 'react-bootstrap/ProgressBar';




// async function resizeImage(file, imgSize) {
//   return new Promise((resolve, reject) => {
//     const maxSize = imgSize;

//     const img = new Image();
//     img.src = URL.createObjectURL(file);

//     img.onload = () => {
//       const { width, height } = img;
//       const isHorizontal = width > height;
//       const newWidth = isHorizontal ? maxSize : (width / height) * maxSize;
//       const newHeight = isHorizontal ? (height / width) * maxSize : maxSize;

//       Resizer.imageFileResizer(
//         file,
//         newWidth,
//         newHeight,
//         'JPEG',
//         100,
//         0,
//         (resizedFile) => {
//           const originalFileName = file.name;
//           const resizedBlob = new File([resizedFile], originalFileName, { type: 'image/jpeg' });
//           resolve(resizedBlob);
//         },
//         'blob',
//         () => {
//           reject(new Error('Failed to resize image.'));
//         }
//       );
//     };

//     img.onerror = (event) => {
//       reject(new Error(`Failed to load image: ${event.message}`));
//     };
//   });
// }

const resizeImage = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1350,
      1350,
      "JPEG",
      100,
      0,
      (resizedFile) => {
        const originalFileName = file.name;
        const resizedBlob = new File([resizedFile], originalFileName, { type: 'image/jpeg' });
        resolve(resizedBlob);
      },
      'blob',//"base64"//
    );
  });




const isViolentImage = async (file, model) => {

  // Créez une image à partir du fichier
  const img = new Image();
  img.src = URL.createObjectURL(file);

  // Attendez que l'image soit chargée avant de la passer au modèle MobileNet
  await new Promise(resolve => img.onload = resolve);

  // Utilisez le modèle pour prédire la probabilité que l'image soit dans chaque catégorie
  const predictions = await model.classify(img);

  predictions.forEach(prediction => {
    console.log(`Probabilité pour ${prediction.className}: ${prediction.probability}`);
  });

  // Définissez des poids pour chaque catégorie en fonction de l'importance que vous voulez leur accorder
  // 'assault_rifle': 0.5,
  // 'revolver': 0.5,
  // 'rifle': 0.2,
  // 'gun': 0.3,
  // 'violence': 0.8,
  // 'weapon': 0.4,
  const violweights = {
    'rifle': 0.4,
    'military uniform': 0.2,
    'assault rifle, assault gun': 0.8,
    'revolver, six-gun, six-shooter': 0.8,
    'projectile, missile': 0.1,
    'missile': 0.1,
    'warplane, military plane': 0.1,
  };



  // Calculez la probabilité globale en fonction des probabilités de chaque catégorie et de leurs poids respectifs
  let violglobalProbability = 0;
  for (const p of predictions) {
    if (p.className in violweights) {
      violglobalProbability += p.probability * violweights[p.className];
      console.log(`violglobalProbability ${p.className}: ${violglobalProbability}`);
    }
  }

  // Vérifiez si la probabilité globale que l'image contienne de la violence ou des armes est supérieure à un seuil donné
  const violthreshold = 0.01; // Vous pouvez ajuster ce seuil en fonction de vos besoins
  const isViolent = violglobalProbability > violthreshold;





  const weights = {
    // 'sea anemone, anemone': 10,
    'catamaran': 1,
    'trimaran': 1,
    'speedboat': 1,
    'schooner': 1,
    'yawl': 1,
    'pirate, pirate ship': 1,
    'fireboat': 1,
    'liner, ocean liner': 1,
    'submarine, pigboat, sub, U-boat': 1,
    'paddlewheel, paddle wheel': 1,


    'grey whale, gray whale, devilfish, Eschrichtius gibbosus, Eschrichtius robustus': 1,

    'seashore, coast, seacoast, sea-coast': 1,
    'lakeside, lakeshore': 1,
    'boathouse': 1,
    'promontory, headland, head, foreland': 1,
    'cliff, drop, drop-off': 1,

    'flagpole, flagstaff': 1,

    'fountain': 1,
    'beacon, lighthouse, beacon light, pharos': 1,


    
    'breakwater, groin, groyne, mole, bulwark, seawall, jetty': 1,
    'dock, dockage, docking facility': 1,
    'pier': 1,
    'suspension bridge': 1,
    'steel arch bridge': 1,
    'dam, dike, dyke': 1,



    'sandbar, sand bar': 1,
    
    'goose': 1,
    'pelican': 1,

    'wreck': 1,
    'canoe': 1,
    'paddle, boat paddle': 1,




    'ski': 1,
    'alp': 1,
    'mountain tent': 1,
    'valley, vale': 1,
    'snowmobile': 1,


    'alley, vale': 1,

  };

  // Calculez la probabilité globale en fonction des probabilités de chaque catégorie et de leurs poids respectifs
  let globalProbability = 0;
  for (const p of predictions) {
    if (p.className in weights) {
      globalProbability += p.probability * weights[p.className];
      console.log(`globalProbability ${p.className}: ${globalProbability}`);
    }
  }

  // Vérifiez si la probabilité globale que l'image contienne qqch identifié est supérieure à un seuil donné
  const threshold = 0.1; // Vous pouvez ajuster ce seuil en fonction de vos besoins
  const isIdentifier = globalProbability > threshold;



  return {isViolent, isIdentifier};
}




const isNotSafeImage = async (file, model) => {

  
  // Créez une image à partir du fichier
  const img = new Image();
  img.src = URL.createObjectURL(file);

  // Attendez que l'image soit chargée avant de la passer au modèle NSFWJS
  await new Promise(resolve => img.onload = resolve);
  // Utilisez le modèle pour prédire la probabilité que l'image soit NSFW
  const predictions = await model.classify(img);
  predictions.forEach(prediction => {
    console.log(`Probabilité pour ${prediction.className}: ${prediction.probability}`);
  });


  // drawings - safe for work drawings (including anime)
  // hentai - hentai and pornographic drawings
  // neutral - safe for work neutral images
  // porn - pornographic images, sexual acts
  // sexy - sexually explicit images, not pornography
  // Définissez des poids pour chaque catégorie en fonction de l'importance que vous voulez leur accorder
  const weights = {
    Drawing: 0,
    Hentai: 0.4,
    Neutral: -0.5,
    Porn: 0.8,
    Sexy: 0,
  };

  // Calculez la probabilité globale en fonction des probabilités de chaque catégorie et de leurs poids respectifs
  let globalProbability = 0;
  for (const p of predictions) {
    globalProbability += p.probability * weights[p.className];
    console.log(`globalProbability ${p.className}: ${globalProbability}`);
  }

  // Vérifiez si la probabilité globale que l'image soit NSFW est supérieure à un seuil donné
  const threshold = 0.1; // Vous pouvez ajuster ce seuil en fonction de vos besoins
  const isNotSafe = globalProbability > threshold;


  return isNotSafe;
}


function ImageUpload() {
  const [progress, setProgress] = useState(0);

  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [show, setShow] = useState(false);
  // const [image, setImage] = useState(null);
  // const [uploadURL, setuploadURL] = useState();

  // const [nsfwModel, setNsfwModel] = useState(null); //useRef(null); //useState(null);
  // const [mobilenetModel, setMobilenetModel] = useState(null); //useRef(null); //useState(null);
  const [modeloaded, setModeloaded] = useState(false); //useRef(null); //useState(null);


  const loadModels = async () => {
    await tf.setBackend('webgl'); // Utiliser le backend WebGL pour les performances dans le navigateur
    const nsfwModel = await nsfwjs.load(); // Charger le modèle NSFW
    const mobilenetModel = await mobilenet.load(); // Charger le modèle Mobilenet
    return { nsfwModel, mobilenetModel };
    print('loadModels')
  };
  
  // const loadModels = async () => {
  //   console.log('loading model');
  //   // setLoading(true);
  //   try {
  //     const nsfwModel = await nsfwjs.load();
  //     const mobilenetModel = await mobilenet.load();
      
  //     setNsfwModel(nsfwModel);
  //     setMobilenetModel(mobilenetModel);
  //     setModeloaded(true);
  //     console.log('model loadé');
  //     // console.log('nsfwModel');
  //     // console.log(nsfwModel);
  //     // console.log('mobilenetModel');
  //     // console.log(mobilenetModel);
  //   } catch (error) {
  //     console.error('Failed to load models:', error);
  //     // setFailed(true);
  //   }
  // };




  const handleImageChange = async (event) => {
 

    // Get user's position
    if (navigator.geolocation) {
      console.log('localisation ok');

      // setLoading(true);
      setLoading(true);
      setProgress(10);

      try {
      const file = event.target.files[0];
      console.log('image chargée');
      setProgress(progress+10);
      // resizeImage(file, imgSize);
      // const ResizedFile = await resizeImage(file, 1350); // Redimensionner le fichier pour upload 1920

        // const file = event.target.files[0];
      const ResizedFile = await resizeImage(file);
      // const ResizedFile = file;
      // const ValidationFile = await resizeImage(file, 100); // Redimensionner le fichier pour verification
      console.log('image rezied');
      setProgress(progress+10);

      const formData = new FormData();
      // formData.append("image", file);
      formData.append("image", ResizedFile);


      
      const { nsfwModel, mobilenetModel } = await loadModels();
      setProgress(progress+10);

      
      // const isNotSafe = await isNotSafeImage(file, nsfwModel); // Attendre que la promesse soit résolue

      const isNotSafe = await isNotSafeImage(ResizedFile, nsfwModel); // Attendre que la promesse soit résolue
      console.log("isNSFW ?");
      console.log(isNotSafe);
      setProgress(progress+10);

      // const {isViolent, isIdentifier} = await isNotSafeImage(file, mobilenetModel); // Attendre que la promesse soit résolue
      const {isViolent, isIdentifier} = await isViolentImage(ResizedFile, mobilenetModel); // Attendre que la promesse soit résolue
      console.log("isViolent ?");
      console.log(isViolent);
      setProgress(progress+10);
      
      console.log("isIdentifier ?");
      console.log(isIdentifier);
      setProgress(progress+17);


      
      //navigator.geolocation.getCurrentPosition((position) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
        formData.append("position", position.coords.latitude + ',' + position.coords.longitude);
        formData.append("latitude", position.coords.latitude);
        formData.append("longitude", position.coords.longitude);

        console.log('position ok');
        setProgress(progress+17);

        // console.log('uploadURL')
        // console.log(uploadURL)
        if (isNotSafe) {
          fetch("./observation/NSFW.php", {
            method: "POST",
            body: formData,
          })
          .then((response) => response.json())
          .then((data) => {
            //setImage(data.imageUrl);
            console.log(data.imageUrl)
          })
          .catch((error) => {console.error(error)});
          console.log("NSFW");
          // L'image n'est pas sûre, affichez l'alerte
          alert("Contenu potentiellement inaproprié. Veuillez télécharger une image différente.");
        } 
        else if (isViolent) {
          fetch("./observation/Violent.php", {
            method: "POST",
            body: formData,
          })
          .then((response) => response.json())
          .then((data) => {
            //setImage(data.imageUrl);
            console.log(data.imageUrl)
          })
          .catch((error) => {
            console.error(error)
            setFailed(true);
            // setLoading(false);
            setLoading(false);
          });
          console.log("Violent");
          // L'image n'est pas sûre, affichez l'alerte
          alert("Contenu potentiellement Violent. Veuillez télécharger une image différente.");
        }
        else if (isIdentifier) {
          fetch("./observation/observation.php", {
            method: "POST",
            body: formData,
          })
          .then((response) => response.json())
          .then((data) => {
            setLoaded(true);
            // setLoading(false);
            setLoading(false);
            //setImage(data.imageUrl);
            console.log(data.imageUrl)
          })
          .catch((error) => {
            console.error(error)
            setFailed(true);
            // setLoading(false);
            setLoading(false);
          });
          console.log("identifier");
          // L'image est  sûre
        }  
        else {
          //alert("Votre image est en cours de validation.");
          fetch("./observation/observation.php", {
            method: "POST",
            body: formData,
          })
          .then((response) => response.json())
          .then((data) => {
            setLoaded(true);
            // setLoading(false);
            setLoading(false);            //setImage(data.imageUrl);
            console.log(data.imageUrl)
          })
          .catch((error) => {
            console.error(error)
            setFailed(true);
            // setLoading(false);
            setLoading(false);
          });
          console.log("?");
        }

      },
      (error) => {
        console.error(error);
        setShow(true);
        //alert("La géolocalisation a échoué. Veuillez autoriser l'accès à votre position dans les réglages systèmes de votre appareil. \n\n Pour autoriser l'accès à la localisation sur un appareil iOS, allez dans \"Réglages\", puis sélectionnez \"Confidentialité\". Ici, vous pouvez autoriser l'accès à votre position en activant l'option \"Services de localisation\" et en autorisant l'accès pour le navigateur web que vous utilisez. \n Pour autoriser l\'accès à la localisation sur un appareil Android, allez dans \"Paramètres\", puis sélectionnez \"Applications et notifications\". Trouvez le navigateur web que vous utilisez, puis sélectionnez \"Autorisations\". Ici, vous pouvez autoriser l'accès à votre position en activant l\'option \"Localisation\".");
        //alert("La géolocalisation a échoué. Veuillez autoriser l'accès à votre position ou entrer manuellement votre position.");
        formData.append("position", 0);
        formData.append("latitude", 0);
        formData.append("longitude", 0);
        // envoyer la requête même si la position n'est pas disponible
        fetch("./observation/SansLieu.php", {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            //setImage(data.imageUrl);
            console.log(data.imageUrl)
          })
          .catch((error) => {
            console.error(error)
            setFailed(true);
            // setLoading(false);
            setLoading(false);
          });
      });

      } catch (err) {
        console.error(err);
        setFailed(true);
        // setLoading(false);
        setLoading(false);
      }

    } else {
      setShow(true);

      formData.append("position", 0);
      formData.append("latitude", 0);
      formData.append("longitude", 0);
      // envoyer la requête même si la position n'est pas disponible
      fetch("./observation/SansLieu.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
        //setImage(data.imageUrl);
        console.log(data.imageUrl)
      })
        .catch((error) => {
            console.error(error)
            setFailed(true);
            // setLoading(false);
            setLoading(false);
          });
    }
    

  };

  return (

    
    <div>


    <label className="upload-button" style={{backgroundColor: "#007bff", color: "white", padding: "10px 15px", borderRadius: "4px", cursor: "pointer", float: "right"}}>
    {/* {modeloaded && */}
    <input type="file" hidden onChange={handleImageChange} />
    {/* // } */}
      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-camera-plus" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" fill="none" strokeLinelinecap="round" strokeLinelinejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <circle cx="12" cy="13" r="3" />
        <path d="M5 7h2a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h2m9 7v7a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2" />
        <line x1="15" y1="6" x2="21" y2="6" />
        <line x1="18" y1="3" x2="18" y2="9" />
      </svg>
    </label>

  {show &&
        <Alert variant="danger" onClose={() => setShow(false)} dismissible  style={{transform: 'translate(-50, 0)'}}>
        <Alert.Heading>Oh non! La géolocalisation a échoué... pas de panique</Alert.Heading>
        <p>
        <br></br>
        <br></br>

        Veuillez autoriser l'accès à votre position dans les <strong>"Réglages Systèmes"</strong> de votre appareil. 
        <br></br>
        <br></br><strong>iOS</strong>: <strong>"Réglages"</strong>&rarr;<strong>"Confidentialité"</strong>&rarr;<strong>"Services de localisation"</strong> et en autorisez l'accès pour le navigateur web que vous utilisez. 
        <br></br>
        <br></br><strong>Android</strong>: <strong>"Paramètres"</strong>&rarr;<strong>"Applications et notifications"</strong>. Trouvez le navigateur web que vous utilisez, puis &rarr;<strong>"Autorisations"</strong>, autoriser l'accès à votre position en activant l'option <strong>"Localisation"</strong>.
        </p>
      </Alert>
      }

  {loading &&
        <Alert variant="warning" onClose={() => setLoading(false)} dismissible  style={{transform: 'translate(-50, 0)'}}>
        <Alert.Heading>Loading... ⏳</Alert.Heading>
        <p>
        <br></br>
        Your image is loading... please wait
        <br></br>
        </p>
          <ProgressBar animated variant="primary" now={progress} key={3} />
          {/* <ProgressBar>
            <ProgressBar variant="success" now={progress} key={1} />
            <ProgressBar striped variant="warning" now={20} key={2} />
            <ProgressBar animated variant="danger" now={progress} key={3} />
          </ProgressBar> */}
      </Alert>
      }

  {loaded &&
        <Alert variant="success" onClose={() => setLoaded(false)} dismissible  style={{transform: 'translate(-50, 0)'}}>
        <Alert.Heading>✅ Image uploaded! 😃</Alert.Heading>
        <p>        
        </p>
      </Alert>
      }
  {failed &&
        <Alert variant="danger" onClose={() => setFailed(false)} dismissible  style={{transform: 'translate(-50, 0)'}}>
        <Alert.Heading>❌ Fail to load 🥲</Alert.Heading>
        <p>        
        </p>
      </Alert>
      }
</div>


      );
}

export default ImageUpload;

//#0D6EFD

// ligne #2c3e50

/*

      <div>
      <h1>Upload an image</h1>
      <form>
        <input type="image" name="image" className="btn btn-primary mt-3" onChange={handleImageChange} />
      </form>
      {image && <img src={image} alt="uploaded image" />}
      </div>
    </div>
    */