// import React, { Component } from 'react';
// import {ReactFlvPlayer} from 'react-flv-player'

// class LiveVideo extends Component {

//   render() {
//     return (
//       <div>
//         <ReactFlvPlayer
//           url = "rtmp://tws.hopto.org:554/bcs/channel0_main.bcs?channel=0&stream=0&user=admin&password=1.852Wind"
//           heigh = "800px"
//           width = "800px"
//           isMuted={true}
//         />
//       </div>
//     );
//   }
// }

// export default LiveVideo;



// import React from 'react';
// import ReactPlayer from 'react-player';

// class LiveVideo extends React.Component {
//   render() {
//     const videoUrl = 'rtmp://tws.hopto.org:554/bcs/channel0_main.bcs?channel=0&stream=0&user=admin&password=1.852Wind'; // Votre URL Reolink

//     return (
//       <div>
//         <ReactPlayer url={videoUrl} playing controls />
//       </div>
//     );
//   }
// }

// export default LiveVideo;





// import React from 'react';
// import ReactPlayer from 'react-player';

// const LiveVideo = () => {
//   const url = 'rtmp://tws.hopto.org:554/bcs/channel0_main.bcs?channel=0&stream=0&user=admin&password=1.852Wind';

//   return (
//     <div>
//       <ReactPlayer
//         url={url}
//         playing={true}
//         controls={true}
//         width="100%"
//         height="auto"
//       />
//     </div>
//   );
// };

// export default LiveVideo;




// import React from 'react';
// import { Player } from 'video-react';

// const LiveVideo = () => {
//   const url = 'rtmp://tws.hopto.org:554/bcs/channel0_main.bcs?channel=0&stream=0&user=admin&password=1.852Wind';

//   return (
//     <div>
//       <Player
//         playsInline
//         src={url}
//         autoPlay
//       />
//     </div>
//   );
// };

// export default LiveVideo;



// import React from 'react';

// const LiveVideo = () => {
//   const videoUrl = 'rtmp://tws.hopto.org:554/bcs/channel0_main.bcs?channel=0&stream=0&user=admin&password=1.852Wind';

//   return (
//     <div>
//       <video controls autoPlay>
//         <source src={videoUrl} type="video/x-flv" />
//         Votre navigateur ne prend pas en charge la lecture de vidéos FLV.
//       </video>
//     </div>
//   );
// };

// export default LiveVideo;

//////////

// import React, { useEffect, useRef } from 'react';
// import flvjs from 'flv.js';

// const LiveVideo = () => {
//   const videoRef = useRef(null);

//   useEffect(() => {
//     if (videoRef.current) {
//       const videoElement = videoRef.current;
//       const flvPlayer = flvjs.createPlayer({
//         type: 'hls',
//         url: 'http://clientportal.link:80/6yjrsES6RO/iRWHJn390/60033',
//       });
//       flvPlayer.attachMediaElement(videoElement);
//       flvPlayer.load();
//       return () => {
//         flvPlayer.destroy();
//       };
//     }
//   }, []);

//   return (
//     <div>
//       <video ref={videoRef} controls autoPlay />
//     </div>
//   );
// };

// export default LiveVideo;


// import 'video.js/dist/video-js.css';
// import videojs from 'video.js';

// import React, { useEffect, useRef } from 'react';
// import videojs from 'video.js';

// const LiveVideo = () => {
//   const videoRef = useRef(null);

//   useEffect(() => {
//     // Vérification si la référence de la vidéo existe
//     if (videoRef.current) {
//       const player = videojs(videoRef.current, {
//         techOrder: ['flash', 'html5'], // Utiliser Flash en premier pour RTMP
//         sources: [{
//           src: 'https://manifest.googlevideo.com/api/manifest/hls_variant/expire/1689018939/ei/2w2sZIKWAsTCjQSrn47wCg/ip/3.129.111.220/id/gxG3pdKvlIs.4/source/yt_live_broadcast/requiressl/yes/hfr/1/playlist_duration/30/manifest_duration/30/maudio/1/spc/Ul2Sq5IPlAtx8o0wmEAE2v4DPUmythmHpRHmjnLM1g/vprv/1/go/1/pacing/0/nvgoi/1/keepalive/yes/fexp/24007246/beids/24350017/dover/11/itag/0/playlist_type/DVR/sparams/expire%2Cei%2Cip%2Cid%2Csource%2Crequiressl%2Chfr%2Cplaylist_duration%2Cmanifest_duration%2Cmaudio%2Cspc%2Cvprv%2Cgo%2Citag%2Cplaylist_type/sig/AOq0QJ8wRQIgVdMBcgVxKeE6q4AzXLCo0srHFLlR9bNuz-soRH_bRG4CIQDteYyAcwfl3vX9Vm5xewLmLgP0rAkpbckbVSJSbWzsdw%3D%3D/file/index.m3u8',
//           type: 'rtmp/flv',
//         }],
//       });

//       // Nettoyer le lecteur lors du démontage du composant
//       return () => {
//         if (player) {
//           player.dispose();
//         }
//       };
//     }
//   }, []);

//   return (
//     <div data-vjs-player>
//       <video ref={videoRef} className="video-js" />
//     </div>
//   );
// };

// export default LiveVideo;






// import React from 'react';
// import ReactPlayer from 'react-player';
// import flvjs from 'flv.js';




// class LiveVideo extends React.Component {

//     constructor(props) {
//       super(props);
//       this.videoPlayer = React.createRef();
//     }
  
//     componentDidMount() {
//       const videoElement = this.videoPlayer.current.getInternalPlayer();
//       if (flvjs.isSupported()) {
//         const flvPlayer = flvjs.createPlayer({
//           type: 'flv',
//           url: 'rtmp://tws.hopto.org:554/bcs/channel0_main.bcs?channel=0&stream=0&user=admin&password=1.852Wind'
//         });
//         flvPlayer.attachMediaElement(videoElement);
//         flvPlayer.load();
//       }
//     }
  
//     render() {
//       return (
//         <div>
//           <ReactPlayer
//             ref={this.videoPlayer}
//             url="rtmp://tws.hopto.org:554/bcs/channel0_main.bcs?channel=0&stream=0&user=admin&password=1.852Wind"
//             playing
//             controls
//           />
//         </div>
//       );
//     }
//   }
  
//   export default LiveVideo;
  


// import React, { Component } from 'react';
// import ReactPlayer from 'react-player';

// class LiveVideo extends Component {
//   render() {
//     return (
//       <div>
//         <ReactPlayer
//           url="http://192.168.1.64/ISAPI/Streaming/channels/1/picture"
//           controls
//           playing
//           width="640px"
//           height="360px"
//         />
//       </div>
//     );
//   }
// }

// export default LiveVideo;




import React from 'react';

class LiveVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snapshotUrl: '',
    };
  }

  componentDidMount() {
    this.fetchSnapshot();
  }

  fetchSnapshot = () => {
    const url = 'http://192.168.1.64/ISAPI/Streaming/channels/1/picture';

    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const snapshotUrl = URL.createObjectURL(blob);
        this.setState({ snapshotUrl });
      })
      .catch(error => {
        console.error('Erreur lors de la récupération de l\'instantané:', error);
      });
  };

  render() {
    return (
      <div>
        <h1>Instantané de la caméra</h1>
        {this.state.snapshotUrl && (
          <img src={this.state.snapshotUrl} alt="Instantané de la caméra" />
        )}
      </div>
    );
  }
}

export default LiveVideo;



