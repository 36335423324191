// import React, { useState } from 'react';
// import Product from './Product';
// import ProductDetails from './ProductDetails';
// import Checkout from './Checkout';

// function Shop() {
//   const [currentRoute, setCurrentRoute] = useState('/');

//   const renderComponent = () => {
//     if (currentRoute === '/') {
//       return <Product />;
//     } else if (currentRoute.startsWith('/product/')) {
//       return <ProductDetails />;
//     } else if (currentRoute === '/checkout') {
//       return <Checkout />;
//     }
//   };

//   return (
//     <div>
//       <nav>
//         <ul>
//           <li>
//             <button onClick={() => setCurrentRoute('/')}>Home</button>
//           </li>
//           <li>
//             <button onClick={() => setCurrentRoute('/product/1')}>Product 1</button>
//           </li>
//           <li>
//             <button onClick={() => setCurrentRoute('/checkout')}>Checkout</button>
//           </li>
//         </ul>
//       </nav>

//       <div>{renderComponent()}</div>
//     </div>
//   );
// }

// export default Shop;



import React from 'react';
// import { BrowserRouter as Router, Switch, Routes, Route } from 'react-router-dom';
// import { HashRouter as Router, Switch, Routes, Route, Link } from 'react-router-dom';
import { HashRouter as Router, Routes, Route, Link } from 'react-router-dom';

import Product from './Product';
import ProductDetails from './ProductDetails';
import Checkout from './Checkout';

function Shop() {

  return (

    <Router>
      <Switch>
      {/* <Routes> */}
        <Route exact path="/" component={<Product/>} />
        <Route path="./product/:id" component={<ProductDetails/>} />
        <Route path="./checkout" component={<Checkout/>} />
      {/* </Routes> */}
      </Switch>
    </Router>
  );
}


// function ProductDetails() {
//   return <h2>ProductDetails</h2>;
// }

// function Checkout() {
//   return <h2>Checkout</h2>;
// }


export default Shop;

