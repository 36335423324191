// import React, { useRef, useState, useEffect, Component} from 'react';
import React, { useState } from 'react';


import Container from 'react-bootstrap/Container';

import Button from 'react-bootstrap/Button';



import Stories from 'react-insta-stories';


// newMap.addSource('points', {
//     type: 'geojson',
//     data: pointSource.data,
//     cluster: true, // enable clustering
//     clusterMaxZoom: 14, // max zoom level for clustering
//     clusterRadius: 50 // cluster radius, in pixels
// });


const Story = () => {
    const [showStory, setShowStory] = useState(true);
    const [playStory, setPlayStory] = useState(true);
    const [currentStory, setCurrentStory] = useState(0);


    // const stories = [
    //     'https://images.pexels.com/photos/9470805/pexels-photo-9470805.jpeg?w=300',
    //     'https://images.pexels.com/photos/9733197/pexels-photo-9733197.jpeg?w=300',
    //     'https://images.pexels.com/photos/9470805/pexels-photo-9470805.jpeg?w=300',
    //   ];

    const stories = [
        // 'https://images.pexels.com/photos/9470805/pexels-photo-9470805.jpeg?w=300',
        {
            url: 'https://images.pexels.com/photos/9733197/pexels-photo-9733197.jpeg?w=300',
            duration: 2000,
            header: {
                heading: 'Mohit Karekar',
                subheading: 'Posted 30m ago',
                profileImage: 'https://picsum.photos/100/100',
            },
        },
        {
            url: 'https://images.pexels.com/photos/9470805/pexels-photo-9470805.jpeg?w=300',
            duration: 2000,
            header: {
                heading: 'Mohit Karekar',
                subheading: 'Posted 30m ago',
                profileImage: 'https://picsum.photos/100/100',
            },
        }
    ];
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



    return (
        <Container fluid>
        
            {/* <Row style={{ position: 'absolute', left: 12, top: 0, zIndex: 11, width:"100%" }}> */}

            {/* { showStory && */}


                <Stories
                    stories={stories}
                    defaultInterval={1500}
                    width={'inherit'}
                    height={'inherit'}
                    // onAllStoriesEnd={() => {
                    //     console.log('story end');
                    //     setShowStory(false)
                    // }}
                    currentIndex={currentStory}
                    
 
                    // onStoryEnd={() => currentStory < stories.length - 1 && setCurrentStory(currentStory + 1)}
                    onStoryStart={() => {
                        setPlayStory(true)
                        setCurrentStory(currentStory)
                    }}

                    onNext={() => currentStory < stories.length - 1 && setCurrentStory(currentStory + 1)}
                    onPreviousStory={() => currentStory > 0 && setCurrentStory(currentStory - 1)}

                    onPaused={() => setPlayStory(false)}
                    onPlay={() => setPlayStory(true)}

                    // onNext={() => console.log('NEXT')}
                    // onPrevious={() => console.log('PREVIOUS')}

                    keyboardNavigation={true}
                    loop={true}
                />
                <Button style={{ position: 'absolute', left: '50%', top: 0, zIndex: 0, width:"50%", height:'100%', opacity: 0 }}  onClick={() => currentStory < stories.length - 1 && setCurrentStory(currentStory + 1)}>NEXT</Button>
                <Button style={{ position: 'absolute', left: '0%', top: 0, zIndex: 0, width:"50%", height:'100%', opacity: 0 }} onClick={() => currentStory > 0 && setCurrentStory(currentStory - 1)}>PREVIOUS</Button>



            {/* } */}

        </Container>


    );
};

export default Story;

