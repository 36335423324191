// import React from "react";

import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBIcon,
  MDBBtn,
  MDBRipple,

  MDBContainer,
  MDBRow,
  MDBCol
} from "mdb-react-ui-kit";

import React, { useEffect, useState } from 'react';
// import WooCommerce from './woocommerce.config';
import api from '../../woocommerce.config';

function Product({ product }) {


  const [products, setProducts] = useState([]);

  useEffect(() => {
    api.get('products')
      .then((response) => {
        setProducts(response.data);
        console.log(response.data)
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  // const imageSrc =product?.images[0].src || "";
  const isNew = product?.isNew || false;
  const ProductName = product?.name || "";
  const ProductPrice = product?.price || "";
  const ProductCategory = product?.category || "";

  return (
    <div>
      <h1>Ma boutique</h1>
      <div className="product-list">
        <MDBContainer fluid className="my-5 text-center">
          <h4 className="mt-4 mb-5">
            <strong>Bestsellers</strong>
          </h4>
          <MDBRow>
            {/* <MDBCol md="12" lg="4" className="mb-4"> */}
            {products.map((product) => (
            <MDBCol md="6" lg="4" className="mb-4">
              <MDBCard>
                <MDBRipple
                  rippleColor="light"
                  rippleTag="div"
                  className="bg-image rounded hover-zoom"
                >
                  <MDBCardImage
                    src={product?.images[0].src || ""}
                    fluid
                    className="w-100"
                  />
                  <a href="#!">
                    <div className="mask">
                      <div class="d-flex justify-content-start align-items-end h-100">
                        <h5>
                          {product?.isNew || false &&
                            <span className="badge bg-primary ms-2">New</span>
                          }
                          <span className="badge bg-success ms-2">Eco</span>
                          <span className="badge bg-danger ms-2">-10%</span>
                        </h5>
                      </div>
                    </div>
                    <div className="hover-overlay">
                      <div
                        className="mask"
                        style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                      ></div>
                    </div>
                  </a>
                </MDBRipple>
                <MDBCardBody>
                  <a href="#!" className="text-reset">
                    <h5 className="card-title mb-3">{product?.name || ""}</h5>
                  </a>
                  <a href="#!" className="text-reset">
                    <p>{product?.category || ""}</p>
                  </a>
                  <h6 className="mb-3">
                    <s>{product?.regular_price || ""}</s>
                    <strong className="ms-2 text-danger">{product?.price || ""} CHF</strong>
                  </h6>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>

            ))}
          </MDBRow>
        </MDBContainer>
      </div>
    </div>
  );
}

export default Product;




