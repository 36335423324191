import React, { useState } from 'react';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import swissimage from '../../image/map/image.png ';

import topo from '../../image/map/topo.png';
import swissimage from '../../image/map/image.png ';
import outdoor from '../../image/map/outdoor.png';
import smooth from '../../image/map/smooth.png';
import dark from '../../image/map/dark.png';


// const SwissLayerIds = [
//   "ch.vbs.swissmilpilotschart",
//   "ch.vbs.patrouilledesglaciers-a_rennen",
//   "ch.swisstopo.swissbathy3d-reliefschattierung",
//   // ... Ajoutez d'autres IDs de couche ici
// ];

const SwissLayer = ({ layerId, map }) => {
    const [isVisible, setIsVisible] = useState(true);
  
    const handleSwissLayerClick = () => {
      setIsVisible(prev => !prev);
      map.setLayoutProperty(layerId, 'visibility', isVisible ? 'none' : 'visible');
    };
  
    return (
      <>
      <Col xs={6} md={4} lg={2} key={layerId.value}>
          <img 
              src={topo} 
              alt={layerId.label}
              style={{
                  width:'100%', 
                  borderRadius:'10%', 
                //   border: selectedLayer === layerId.value ? '4px solid #FF5923' : '1px solid #0D6EFD',
              }}
              onClick={() => handleSwissLayerClick(layerId.value)}
          /> 
          <br /><br />
      </Col>
      </>
    );
  };
  
  export default SwissLayer;
  
