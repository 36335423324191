import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';


import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';


import SudFr12 from '../image/logo-telegram/SudFr12_384x384.png';
import SudFr20 from '../image/logo-telegram/SudFr20_384x384.png';
import SudFr30 from '../image/logo-telegram/SudFr30_384x384.png';

import Swiss12 from '../image/logo-telegram/Swiss12_384x384.png';
import Swiss20 from '../image/logo-telegram/Swiss20_384x384.png';
import Swiss30 from '../image/logo-telegram/Swiss30_384x384.png';

import GVA12 from '../image/logo-telegram/GVA12_384x384.png';
import GVA20 from '../image/logo-telegram/GVA20_384x384.png';
import GVA30 from '../image/logo-telegram/GVA30_384x384.png';

function Telegram () {




  return (
    <Container fluid>
        
            {/* <Row>
                <Col></Col>
                <Col>
                    <br></br>
                    <Alert className="text-center" variant='warning' style={{ width: '18rem' }}>
                        <h2>Gold Sponsor</h2>
                    </Alert>
                    <Card className="text-center" >
                            <Card.Header bg='warning'>Featured</Card.Header>
                            <Card.Body>
                                <Card.Title>Special title treatment</Card.Title>
                                <Card.Text>
                                    With supporting text below as a natural lead-in to additional content.
                                </Card.Text>
                                <Button variant="primary">Go somewhere</Button>
                            </Card.Body>
                            {/* <br></br>
                                <Card.Img variant="bottom" src={tudor} /> *}
                            <Card.Footer className="text-muted">2 days ago</Card.Footer>
                    </Card>
                    
                </Col>
                <Col></Col>
            </Row> */}

            


            <Row>
                <Col>
                    <br></br>
                    <Card className="text-center" >
                        <Card.Header bg='warning'>@TrueWindSpeed12ktsGeneve</Card.Header>
                        <Card.Body>
                            <Card.Title>TrueWindSpeed 12kts Genève</Card.Title>
                            <Card.Text>
                                Alerte quand y’a entre 12 et 20 noeuds à Genève
                                <br></br>
                                <Card.Img variant="bottom" src={GVA12} style={{ maxWidth: '100px', maxHeight: '100px'}}/>
                            </Card.Text>
                            <Button variant="primary">
                                <a href="https://t.me/TrueWindSpeed12ktsGeneve" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>Rejoindre le canal</a>
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <br></br>
                    <Card className="text-center" >
                        <Card.Header bg='warning'>@TrueWindSpeed20ktsGeneve</Card.Header>
                        <Card.Body>
                            <Card.Title>TrueWindSpeed 20kts Genève</Card.Title>
                            <Card.Text>
                                Alerte quand y’a entre 20 et 30 noeuds à Genève
                                <br></br>
                                <Card.Img variant="bottom" src={GVA20} style={{ maxWidth: '100px', maxHeight: '100px'}}/>
                            </Card.Text>
                            <Button variant="primary">
                                <a href="https://t.me/TrueWindSpeed20ktsGeneve" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>Rejoindre le canal</a>
                            </Button>
                        </Card.Body>

                    </Card>
                    
                </Col>
                <Col>
                    <br></br>
                    <Card className="text-center" >
                        <Card.Header bg='warning'>@TrueWindSpeed30ktsGeneve</Card.Header>
                        <Card.Body>
                            <Card.Title>TrueWindSpeed 30kts Genève</Card.Title>
                            <Card.Text>
                                Alerte quand y’a plus que 30 noeuds à Genève
                                <br></br>
                                <Card.Img variant="bottom" src={GVA30} style={{ maxWidth: '100px', maxHeight: '100px'}}/>
                            </Card.Text>
                            <Button variant="primary">
                                <a href="https://t.me/TrueWindSpeed30ktsGeneve" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>Rejoindre le canal</a>
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
                <br></br>
            </Row>



            <Row>
                <Col>
                    <br></br>
                    <Card className="text-center" >
                        <Card.Header bg='warning'>@TrueWindSpeed12ktsSwitzerland</Card.Header>
                        <Card.Body>
                            <Card.Title>TrueWindSpeed 12kts Switzerland</Card.Title>
                            <Card.Text>
                                Alerte quand y’a entre 12 et 20 noeuds à proximité des lacs Suisse
                                <br></br>
                                <Card.Img variant="bottom" src={Swiss12} style={{ maxWidth: '100px', maxHeight: '100px'}}/>
                            </Card.Text>
                            <Button variant="primary">
                                <a href="https://t.me/TrueWindSpeed12ktsSwitzerland" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>Rejoindre le canal</a>
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <br></br>
                    <Card className="text-center" >
                        <Card.Header bg='warning'>@TrueWindSpeed20ktsSwitzerland</Card.Header>
                        <Card.Body>
                            <Card.Title>TrueWindSpeed 20kts Switzerland</Card.Title>
                            <Card.Text>
                                Alerte quand y’a entre 20 et 30 noeuds à proximité des lacs Suisse
                                <br></br>
                                <Card.Img variant="bottom" src={Swiss20} style={{ maxWidth: '100px', maxHeight: '100px'}}/>
                            </Card.Text>
                            <Button variant="primary">
                                <a href="https://t.me/TrueWindSpeed20ktsSwitzerland" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>Rejoindre le canal</a>
                            </Button>
                        </Card.Body>
                    </Card>
                    
                </Col>
                <Col>
                    <br></br>
                    <Card className="text-center" >
                        <Card.Header bg='warning'>@TrueWindSpeed30ktsSwitzerland</Card.Header>
                        <Card.Body>
                            <Card.Title>TrueWindSpeed 30kts Switzerland</Card.Title>
                            <Card.Text>
                                Alerte quand y’a plus que 30 noeuds à proximité des lacs Suisse
                                <br></br>
                                <Card.Img variant="bottom" src={Swiss30} style={{ maxWidth: '100px', maxHeight: '100px'}}/>
                            </Card.Text>
                            <Button variant="primary">
                                <a href="https://t.me/TrueWindSpeed30ktsSwitzerland" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>Rejoindre le canal</a>
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
                <br></br>
            </Row>



            <Row>
                <Col>
                    <br></br>
                    <Card className="text-center" >
                        <Card.Header bg='warning'>@TrueWindSpeed12ktsSudFrance</Card.Header>
                        <Card.Body>
                            <Card.Title>TrueWindSpeed 12kts Sud de la France</Card.Title>
                            <Card.Text>
                                Alerte quand y’a entre 12 et 20 noeuds dans le Sud de la France
                                <br></br>
                                <Card.Img variant="bottom" src={SudFr12} style={{ maxWidth: '100px', maxHeight: '100px'}}/>
                            </Card.Text>
                            <Button variant="primary">
                                <a href="https://t.me/TrueWindSpeed12ktsSudFrance" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>Rejoindre le canal</a>
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <br></br>
                    <Card className="text-center" >
                        <Card.Header bg='warning'>@TrueWindSpeed20ktsSudFrance</Card.Header>
                        <Card.Body>
                            <Card.Title>TrueWindSpeed 20kts Sud de la France</Card.Title>
                            <Card.Text>
                                Alerte quand y’a entre 20 et 30 noeuds dans le Sud de la France
                                <br></br>
                                <Card.Img variant="bottom" src={SudFr20} style={{ maxWidth: '100px', maxHeight: '100px'}}/>
                            </Card.Text>
                            <Button variant="primary">
                                <a href="https://t.me/TrueWindSpeed20ktsSudFrance" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>Rejoindre le canal</a>
                            </Button>
                        </Card.Body>
                    </Card>
                    
                </Col>
                <Col>
                    <br></br>
                    <Card className="text-center" >
                        <Card.Header bg='warning'>@TrueWindSpeed30ktsSudFrance</Card.Header>
                        <Card.Body>
                            <Card.Title>TrueWindSpeed 30kts Sud de la France</Card.Title>
                            <Card.Text>
                                Alerte quand y’a plus que 30 noeuds dans le Sud de la France
                                <br></br>
                                <Card.Img variant="bottom" src={SudFr30} style={{ maxWidth: '100px', maxHeight: '100px'}}/>
                            </Card.Text>
                            <Button variant="primary">
                                <a href="https://t.me/TrueWindSpeed30ktsSudFrance" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>Rejoindre le canal</a>
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
                <br></br>
            </Row>

            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>



    </Container>
  );
}


export default Telegram;


