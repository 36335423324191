import React, { useRef, useState, useEffect, Component} from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';



import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

// import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
// import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';



// import ImageUpload from "./ImageUpload";
import ImageUpload from './ImageUpload'; // Importation de loading depuis ImageUpload




import Stories from 'react-insta-stories';


import tws_icon from '/img/icon-192x192.png';

import { IconEye, IconX } from '@tabler/icons-react';



import moment from 'moment';
import 'moment/locale/fr'; // Importez la localisation que vous souhaitez utiliser, par exemple 'fr' pour le français
moment.locale(); // Active la localisation automatique en utilisant la région par défaut du navigateur




// import tws_icon from '/img/icon-48x48.png';

// newMap.addSource('points', {
//     type: 'geojson',
//     data: pointSource.data,
//     cluster: true, // enable clustering
//     clusterMaxZoom: 14, // max zoom level for clustering
//     clusterRadius: 50 // cluster radius, in pixels
// });

const MapCam = ({
    centerLongitude,
    centerLatitude,
    zoom,
    pitch,
    bearing,
    exaggeration,
    defstyleUrl,

    onMove,
    changeURL,
    changeTimelaps,
    deftimelaps,
    images,
    tracker

}) => {



    const mapContainerRef = useRef(null);
    //const map = useRef(null);
    const [styleUrl, setStyleUrl] = useState(defstyleUrl);
    const [timelaps, setTimelaps] = useState(deftimelaps); 

    const [map, setMap] = useState(null);


    const [showStory, setShowStory] = useState(false);
    const [playStory, setPlayStory] = useState(true);
    const [currentStory, setCurrentStory] = useState(0);

    const [swipeUp, setSwipeUp] = useState(false);
    const [rowOpacity, setRowOpacity] = useState(1);
    
    
    const [touchStartY, setTouchStartY] = useState(0);


    const [isMoving, setIsMoving] = useState(false);



    // const stories = [
    //     'https://images.pexels.com/photos/9470805/pexels-photo-9470805.jpeg?w=300',
    //     'https://images.pexels.com/photos/9733197/pexels-photo-9733197.jpeg?w=300',
    //     'https://images.pexels.com/photos/9470805/pexels-photo-9470805.jpeg?w=300',
    //   ];

    const storie = [
        {
            url: 'https://images.pexels.com/photos/9733197/pexels-photo-9733197.jpeg?w=300',
            duration: 5000,
            header: {
                heading: 'TrueWindSpeed',
                subheading: `Posted 10h ago`,
                profileImage: 'https://picsum.photos/100/100',
            },
        },
    ];
    const [stories, setStories] = useState(storie);

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



    const createPopups = (images, map) => {

        map.on('load', function() {

        // Ajouter la source de données des popups à la carte
        map.addSource('popups', {
          type: 'geojson',
          data: images.data,
          cluster: true, // Activer le clustering
          clusterMaxZoom: 14, // Zoom maximum pour le clustering
          clusterRadius: 50 // Rayon de chaque cluster en pixels
        });

        




// // // // // // // // // // // // // // // // // // // // // // // // // // // // // 
        // Ajouter une couche pour agrandir la zone de click des clusters de popups
        map.addLayer({
            id: 'popup-clusters',
            type: 'circle',
            source: 'popups',
            filter: ['has', 'point_count'],
            paint: {
              'circle-blur':1.5,
              'circle-color': [
                'step',
                ['get', 'point_count'],
                '#51bbd6',
                20,'#f1f075',
                100,'#f28cb1',
                500, '#F23C4F'
              ],
              'circle-radius': [
                'step',
                ['get', 'point_count'],
                40, 
                20, 60, 
                100, 80,
                500, 100
              ]
            },
          });        
        // Ajouter une couche pour les clusters de popups
        map.addLayer({
          id: 'popup-clusterss',
          type: 'circle',
          source: 'popups',
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': [
              'step',
              ['get', 'point_count'],
              '#51bbd6',
              20,'#f1f075',
              100,'#f28cb1',
              500, '#F23C4F'
            ],
            'circle-radius': [
              'step',
              ['get', 'point_count'],
              20, 
              20, 30, 
              100, 40,
              500, 50
            ]
          },
        });
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // 






        map.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: 'popups',
            filter: ['has', 'point_count'],
            layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['Frutiger Neue Condensed Regular'],
            // Stadia Regular
            // Stadia Italic
            // Stadia Bold
            // Stadia Semibold
            //'text-font': ['Noto Sans Arabic Light'],
            //'text-font': ['Swisstopo Standard'],
            'text-size': 12
            }
        });

        



// // // // // // // // // // // // // // // // // // // // // // // // // // // // // 
        // Ajouter une couche  pour agrandir la zone de click des popups individuelles
        map.addLayer({
            id: 'popup-unclustered',
            type: 'circle',
            source: 'popups',
            filter: ['!', ['has', 'point_count']],
            paint: {
              'circle-blur': 1.2, //0.99,
            //   'circle-color': '#11B4FF',//'#11D2DA',//'#1156DA',//'#11b4da',
              'circle-color': '#FFFFFF',//'#11D2DA',//'#1156DA',//'#11b4da',
              'circle-radius': 50,
            //   'circle-stroke-width': 4,
            //   // 'circle-stroke-opacity': 0.8,
            //   'circle-stroke-color': '#fff'
            }
          });
      
        // Ajouter une couche pour les popups individuelles
        map.addLayer({
          id: 'popup-unclusteredd',
          type: 'circle',
          source: 'popups',
          filter: ['!', ['has', 'point_count']],
          paint: {
            // 'circle-blur':0.1,
            // 'circle-blur':0.1,
            'circle-color': '#11B4FF',//'#11D2DA',//'#1156DA',//'#11b4da',
            'circle-radius': 12,

            'circle-stroke-width': 1, // 3,
            // 'circle-stroke-opacity': 0.8,
            'circle-stroke-color': '#FFFFFF'
          }
        });
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // 


        map.on('move', function () {
            onMove(
                map.getCenter().lng,
                map.getCenter().lat,
                map.getZoom());
        });

        map.on('movestart', () => {
            setIsMoving(true);
          });
          map.on('move', () => {
            setIsMoving(false);
          });
      
          map.on('moveend', () => {
            setIsMoving(false);
          });
      
          map.on('click', (event) => {
            if (!isMoving) {
                setShowStory(false);
            }
          });


        // Inspecter un cluster lors d'un clic
        map.on('click', 'popup-clusters', function (e) {
          const features = map.queryRenderedFeatures(e.point, { layers: ['popup-clusters'] });
          const clusterId = features[0].properties.cluster_id;
          map.getSource('popups').getClusterExpansionZoom(clusterId, function (err, zoom) {
            if (err) return;
      
            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom
            });
          });
        });
      
        // Ouvrir une popup pour une popup individuelle lors d'un clic
        map.on('click', 'popup-unclustered', function (e) {
          const coordinates = e.features[0].geometry.coordinates.slice();
          const imgPath = e.features[0].properties.image_path;
          const datetime = e.features[0].properties.datetime;
          const imageName = e.features[0].properties.image_name;

          const timeAgo = moment(datetime).fromNow();


            // const currentTime = new Date();
            // const postedTime = new Date(datetime);
            // const timeDifference = currentTime - postedTime;
            // const timeAgo = calculateTimeAgo(timeDifference);

            // function calculateTimeAgo(time) {
            //     const seconds = Math.floor(time / 1000);
            //     const minutes = Math.floor(seconds / 60);
            //     const hours = Math.floor(minutes / 60);
            //     const days = Math.floor(hours / 24);

            //     if (days > 0) {
            //     return `Posted ${days} days ago`;
            //     } else if (hours > 0) {
            //     return `Posted ${hours} hours ago`;
            //     } else if (minutes > 0) {
            //     return `Posted ${minutes} minutes ago`;
            //     } else {
            //     return `Posted a few seconds ago`;
            //     }
            // }


          setStories(
                [
                {
                    url: imgPath,
                    duration: 5000,
                    header: {
                        heading: 'TrueWindSpeed',
                        subheading: `Posted ${timeAgo}`,
                        profileImage: tws_icon,
                    },
                },
                ]
            )

            setShowStory(true)
      

          
            // const popup = new maplibregl.Popup({closeButton: false, closeOnClick: true})
            // //const popup = new maplibregl.Popup()
            //     .setHTML(`
            //         <div>
            //             <img src="${imgPath}" style="max-width: 100%; max-height: 100%;">
            //             <p>${datetime}</p>
            //         </div>
            //     `)
            //                             /*<p>${imageName}</p*/
            //     .setLngLat(coordinates)
            //     .addTo(map);


            // // map.flyTo({
            // //     center: e.features[0].geometry.coordinates
            // // });
            // // popup.setOffset([0, popup._container.offsetHeight/2]);

            // // var taille = Math.round(0.97*Math.min(window.innerWidth, window.innerHeight));
            // var taille = Math.round(0.9*Math.min(window.innerWidth, window.innerHeight));
            // popup.setMaxWidth(taille+'px');
            // //.setOffset([0, -y]);
                
                
        });



        map.on('mouseenter', 'popup-clusters', function () {
            map.getCanvas().style.cursor = 'pointer';
            });
        map.on('mouseleave', 'popup-clusters', function () {
            map.getCanvas().style.cursor = '';
            });
        map.on('mouseenter', 'popup-unclustered', function () {
            map.getCanvas().style.cursor = 'pointer';
            });
        map.on('mouseleave', 'popup-unclustered', function () {
            map.getCanvas().style.cursor = '';
            });

        });
      
    };





    useEffect(() => {
        if (map) {
            console.log('map')
            // Update the map when the styleUrl changes
            //map.setStyle(styleUrl);
            
            // map.on('load', function() {
            if (images) {

                const existingSource = map.getSource('popups');
                console.log(existingSource)

                if (existingSource) {
                    existingSource.setData(images.data);
                    console.log(map.getSource('popups'))

                    console.log('point update')
                    console.log(images.data)
                } 
                // else {
                //     console.log('point create')
                //     createPopups(images, map);

                //     map.on('move', function () {
                //         onMove(
                //             map.getCenter().lng,
                //             map.getCenter().lat,
                //             map.getZoom(),
                //             timelaps);
                //     });
                // }
                



            } else {
                console.log('PAS createPopups')
            }

        //   });
        } else {
            console.log('PAS map')
            // console.log('images')
            // console.log(images)

          // Initialize the map with the initial values
          const newMap = new maplibregl.Map({
            container: mapContainerRef.current,
            style: styleUrl,
            center: [centerLongitude, centerLatitude],
            zoom: zoom,
            pitch: pitch,
            bearing: bearing,
            exaggeration: 8,
          });
      
          // Set the map state
          setMap(newMap);
            
        //   var nav = new maplibregl.NavigationControl();
        //   newMap.addControl(nav, 'top-right');

          newMap.addControl(new maplibregl.GeolocateControl({
              positionOptions: {
                  enableHighAccuracy: true
              },
              trackUserLocation: true,
          }));
      
          newMap.on('style.load', function() {
            
            if (images) {
                
                createPopups(images, newMap);
                //createmarker(images, newMap);

                newMap.on('move', function () {
                    // setShowStory(false);
                    onMove(
                        newMap.getCenter().lng,
                        newMap.getCenter().lat,
                        newMap.getZoom());
                    // onMove(
                    //     newMap.getCenter().lng,
                    //     newMap.getCenter().lat,
                    //     newMap.getZoom(),
                    //     timelaps);
                });
                newMap.on('movestart', () => {
                    setIsMoving(true);
                  });
              
                  newMap.on('moveend', () => {
                    setIsMoving(false);
                  });
              
                  newMap.on('click', (event) => {
                    if (!isMoving) {
                        setShowStory(false);
                    }
                  });
                  

            }

          });
          
        }

    }, [images]);
      
        


    const handletimelapsChange = (value) => {
        setTimelaps(value);
        changeTimelaps(value);
    };

    
    // let touchStartY = 0;

    const handleTouchStart = (event) => {
        console.log('TouchStart');

        setTouchStartY(event.touches[0].clientY);
        console.log(touchStartY);
    };

    const handleTouchEnd  = (event) => {
        console.log('TouchEnd ');
        setRowOpacity(1);
    };

    const handleDoubleClick  = (event) => {
        // console.log('double ');
        // setShowStory(false);
    };
    
    const handleTouchMove = (event) => {

        const touchCurrentY = event.touches[0].clientY;
        const touchMoveDistance = Math.abs(touchCurrentY - touchStartY);

        // Calculer l'opacité relative en fonction de la distance de déplacement
        const opacityy = 1 - touchMoveDistance/ 10000;

        // Mettre à jour la valeur de l'opacité
        setRowOpacity(1 - Math.abs(touchMoveDistance*touchMoveDistance)/ 10000);
        
        // console.log('opacityy');
        // console.log(opacityy);

        
        console.log('touchMoveDistance');
        console.log(touchMoveDistance);
        

        if (touchMoveDistance > 50) {
            setShowStory(false);
            setRowOpacity(1);

          // Action à déclencher lorsque le mouvement est assez grand
          console.log('Balayage vers le haut détecté');
        }


      };
      


    // const allstories = (value) => {
    //     if (images && images.data && images.data.features && images.data.features.length > 0) {
    //       setShowStory(true);
      
    //       const filteredFeatures = images.data.features.filter((feature) => {
    //         const imgLongitude = parseFloat(feature.properties.image_longitude);
    //         const imgLatitude = parseFloat(feature.properties.image_latitude);
    //         const imgPath = feature.properties.image_path;
    //         const datetime = feature.properties.datetime;
      
    //         const distance = geolib.getDistance(
    //           { latitude: centerLatitude, longitude: centerLongitude },
    //           { latitude: imgLatitude, longitude: imgLongitude }
    //         );
      
    //         // Définissez la distance maximale en mètres pour inclure l'image dans le périmètre
    //         const maxDistance = 1000; // Par exemple, 1000 mètres
      
    //         return distance <= maxDistance;
    //       });
      
    //       const stories = filteredFeatures.map((feature) => {
    //         const imgPath = feature.properties.image_path;
    //         const datetime = feature.properties.datetime;
    //         const timeAgo = moment(datetime).fromNow();
      
    //         return {
    //           url: imgPath,
    //           duration: 5000,
    //           header: {
    //             heading: 'TrueWindSpeed',
    //             subheading: `Posted ${timeAgo}`,
    //             profileImage: tws_icon,
    //           },
    //         };
    //       });
      
    //       setStories(stories);
    //     }
    //   };
    
    const allstories = (value) => {

        const bounds = map.getBounds();
        const ne = bounds.getNorthEast(); // Coordonnées du coin nord-est de la zone visible
        const sw = bounds.getSouthWest(); // Coordonnées du coin sud-ouest de la zone visible

        const minLongitude = sw.lng; // Longitude minimale
        const maxLongitude = ne.lng; // Longitude maximale
        const minLatitude = sw.lat; // Latitude minimale
        const maxLatitude = ne.lat; // Latitude maximale


        const stories = images.data.features.filter((feature) => {
            const imgLongitude = parseFloat(feature.properties.image_longitude);
            const imgLatitude = parseFloat(feature.properties.image_latitude);
        
            // Vérifier si les coordonnées de l'image se trouvent dans la zone visible
            return (
                imgLongitude >= minLongitude &&
                imgLongitude <= maxLongitude &&
                imgLatitude >= minLatitude &&
                imgLatitude <= maxLatitude
            );
        }).map((feature) => {
            if (images && images.data && images.data.features && images.data.features.length > 0) {
                setShowStory(true);


                
                    
                const imgPath = feature.properties.image_path;
                const datetime = feature.properties.datetime;
        
                const timeAgo = moment(datetime).fromNow();
        
                return {
                    url: imgPath,
                    duration: 5000,
                    header: {
                        heading: 'TrueWindSpeed',
                        subheading: `Posted ${timeAgo}`,
                        profileImage: tws_icon,
                    },
                };

            }
        });
        setStories(stories);

    
    };
    




    return (
        <Container fluid>

            <Row style={{ position: 'absolute', top: 60, right: 20, zIndex: 4 }}>
                {/* <Button variant="outline-primary" size="lg" onClick={() => setShowStory(true)}> */}
                {/* <Button variant="outline-primary" size="lg" onClick={allstories}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                        <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"></path>
                    </svg>
                
                </Button> */}
                {/* <label style={{backgroundColor: "#007bff", color: "white", padding: "10px 15px", borderRadius: "4px", cursor: "pointer", float: "right"}}> */}
                {/* <label style={{ backgroundColor: "transparent", color: "#007bff", padding: "10px 15px", borderRadius: "4px", cursor: "pointer", float: "right", border: "1px solid #007bff" }}> */}
                <label style={{backgroundColor: "#007bff", color: "white", padding: "10px 15px", borderRadius: "4px", cursor: "pointer", float: "right"}}>
                    <input hidden onClick={allstories} />
                    {/* <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                        <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"></path>
                    </svg> */}
                    <IconEye />
                </label>
        
            
            </Row>








            {/*<Row style={{ position: 'absolute', top: 10, left: 10, zIndex: 2 }}>
                <ToggleButtonGroup type="radio" name="options" defaultValue={styleUrl} onChange={() => handleStyleUrlChange(event.target.value)}>
                    <ToggleButton variant="primary" size="sm" id="tbg-radio-1" value={'https://api.maptiler.com/maps/basic-v2/style.json?key=eCz7oVi0RFfas03fdaFu'}>
                        OpenStreetMap
                    </ToggleButton>

                    <ToggleButton variant="primary" size="sm" id="tbg-radio-2" value={'https://vectortiles.geo.admin.ch/styles/ch.swisstopo.leichte-basiskarte.vt/style.json'}>
                        Swisstopo
                    </ToggleButton>

                    <ToggleButton variant="primary" size="sm" id="tbg-radio-3" value={'https://vectortiles.geo.admin.ch/styles/ch.swisstopo.leichte-basiskarte-imagery.vt/style.json'}>
                        SwissImage
                    </ToggleButton>

                    {/*<ToggleButton variant="primary" size="sm" id="tbg-radio-4" value={'https://api.maptiler.com/maps/outdoor-v2/style.json?key=eCz7oVi0RFfas03fdaFu'}>
    Outdoor
</ToggleButton>*/}{/*

                </ToggleButtonGroup>

            </Row>*/}


            <Row style={{ position: 'absolute', top: 10, left: 10, zIndex: 4 }}>
                

                <ToggleButtonGroup type="radio" name="tempsOptions" defaultValue={deftimelaps} onChange={(value) => handletimelapsChange(value)} >
                    <ToggleButton variant="primary" size="sm" id="tbg-radio-temps-1" value={0.25}>
                        15 min
                    </ToggleButton>
                    <ToggleButton variant="primary" size="sm" id="tbg-radio-temps-2" value={0.5}>
                        30 min
                    </ToggleButton>
                    <ToggleButton variant="primary" size="sm" id="tbg-radio-temps-3" value={1}>
                        1 h
                    </ToggleButton>
                    <ToggleButton variant="primary" size="sm" id="tbg-radio-temps-4" value={3}>
                        3 h
                    </ToggleButton>
                    <ToggleButton variant="primary" size="sm" id="tbg-radio-temps-5" value={6}>
                        6 h
                    </ToggleButton>
                    <ToggleButton variant="primary" size="sm" id="tbg-radio-temps-6" value={24}>
                        24 h
                    </ToggleButton>
                    <ToggleButton variant="primary" size="sm" id="tbg-radio-temps-7" value={168}>
                        1 sem
                    </ToggleButton>
                    {/* <ToggleButton variant="primary" size="sm" id="tbg-radio-temps-8" value={8760}>
                        1 an
                    </ToggleButton> */}

                    {/*<ToggleButton variant="primary" size="sm" id="tbg-radio-4" value={'https://api.maptiler.com/maps/outdoor-v2/style.json?key=eCz7oVi0RFfas03fdaFu'}>
    Outdoor
</ToggleButton>*/}

                </ToggleButtonGroup>

            </Row>

            <Row style={{ position: 'absolute', top: 65, left: 10, zIndex: 3, marginRight:0 }}>
                <ImageUpload/>
            </Row>



            <Row>

                <div
                    ref={mapContainerRef}
                    style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 55,
                        zIndex: 1
                    }}
                />
            </Row>

            {/* {loading &&
                // <Alert variant="light" onClose={() => setLoading(false)} dismissible  style={{transform: 'translate(-50, 0)', zIndex: 4}} >
                <Alert variant="light" onClose={() => setLoading(false)} dismissible  style={{zIndex: 4, maxWidth: '100%', maxHeight: '100%'}} >
                <Alert.Heading>Loading... ⏳</Alert.Heading>
                <p>
                <br></br>
                Your image is loading... please wait
                <br></br>
                </p>
                <div>
                        <img src="https://media.ouest-france.fr/v1/pictures/MjAyMTA4NWM4Y2EzODAwNDMyNDVlMDNhOThmMTFiMTFmZDc2MzU?width=1260&height=708&focuspoint=53%2C62&cropresize=1&client_id=bpeditorial&sign=36e077904d79b32c4d5ac1f4f9eb980f5de44072238d2e0c3ee5ac289fad093f" style={{ maxWidth: '100%', maxHeight: '100%' }}></img>
                        <p>datetime</p>
                </div>
                <Stories width="400px" height="600px" stories={stories}  style={{ position: 'absolute', top: 10, left: 10, zIndex: 8 }}/>

            </Alert>
            } */}
            {/* <Row style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, zIndex: 9 }}> */}
            

            { showStory && !swipeUp && 

            // <Row style={{ position: 'absolute', left: 12, top: 0, zIndex: 11, width:"100%" }}>
            // <Row style={{ position: 'absolute', left: 10, top: 0, zIndex: 11, width:"47vh" }}>

            // <Row style={{ position: 'fixed', left: 12, top: 0, zIndex: 11, maxWidth:"100vw", opacity:rowOpacity}} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd ={handleTouchEnd }>
            // <Row style={{ position: 'absolute', left: 12, top: 0, zIndex: 11, opacity:rowOpacity}} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd ={handleTouchEnd }>
            // <Row style={{ position: 'absolute', left: 0, top: 0, zIndex: 11, opacity:rowOpacity}} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd ={handleTouchEnd }>
            <Row style={{ position: 'fixed', maxWidth:'100vh', left: 0, top: 0, zIndex: 11, opacity:rowOpacity}} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} onDoubleClick={handleDoubleClick}>

{/* onWheel */}

            {/* <Button style={{ position: 'absolute', right: '10vw', top: '10vw', zIndex: 12, width:"15vw", height:'15vw%', opacity: 1 }}  onClick={() => setShowStory(false)}><IconX/></Button> */}
            {/* <div style={{ position: 'absolute', right: '15vw', top: '3vh', zIndex: 12, width:"5vw", height:'5vw', opacity: 1 }}  onClick={() => setShowStory(false)}><IconX  size={50}/></div> */}
            {/* <div style={{ position: 'absolute', right: '3vh', top: '3vh', zIndex: 12, width:"5vw", height:'5vw', opacity: 1 }}  onClick={() => setShowStory(false)}><IconX  size={50}/></div> */}


            {/* <Row style={{ position: 'absolute', left: 0, top: 0, zIndex: 11, width:"100vw"}}> */}

            {/* <div onTouchMove={handleSwipeUp} onMouseMove={handleSwipeUp}> */}


                {/* <Stories
                    stories={stories}
                    defaultInterval={1500}
                    // width={'inherit'}
                    // height={'inherit'}
                    width={'inerit'}
                    height={'100vh'}
                    // height={'inerit'}

                    // onAllStoriesEnd={() => {
                    //     console.log('story end');
                    //     setShowStory(false)
                    // }}
                    onAllStoriesEnd={() => {
                        setShowStory(false);
                        setCurrentStory(0);
                        setSwipeUp(false); // Réinitialisez l'état du balayage vers le haut
                        }
                    }
                    
                    currentIndex={currentStory}
                    
 
                    // onStoryEnd={() => currentStory < stories.length - 1 && setCurrentStory(currentStory + 1)}
                    onStoryStart={() => {
                        setPlayStory(true)
                        setCurrentStory(currentStory)
                    }}

                    // onNext={() => currentStory < stories.length - 1 && setCurrentStory(currentStory + 1)}
                    // onPreviousStory={() => currentStory > 0 && setCurrentStory(currentStory - 1)}

                    onPaused={() => setPlayStory(false)}
                    onPlay={() => setPlayStory(true)}

                    // onNext={() => console.log('NEXT')}
                    // onPrevious={() => console.log('PREVIOUS')}

                    keyboardNavigation={true}
                    // loop={true}
                /> */}

                <Stories
                // loop
                keyboardNavigation
                defaultInterval={3000}
                width={'inerit'}
                
                height={'100vh'}
                // height={'inerit'}

                stories={stories}
                onStoryEnd={(s, st) => console.log("story ended", s, st)}
                onAllStoriesEnd={(s, st) => setShowStory(false)} //console.log("all stories ended", s, st)
                onStoryStart={(s, st) => console.log("story started", s, st)}
                onNext={() => console.log('NEXT')}
                />

                {/* <Button style={{ position: 'absolute', left: '50%', top: 0, zIndex: 0, width:"50%", height:'100%', opacity: 0 }}  onClick={() => currentStory < stories.length - 1 ? setCurrentStory(currentStory + 1) : setShowStory(false)}>NEXT</Button> */}
                {/* <Button style={{ position: 'absolute', left: '50%', top: 0, zIndex: 0, width:"50%", height:'100%', opacity: 0 }} onClick={() => {
                    if (currentStory < stories.length - 1) {
                    setCurrentStory(currentStory + 1);
                    console.log('NEXT');
                    } else {
                    setShowStory(false);
                    setCurrentStory(0);
                    }
                }}
                >NEXT</Button>

                <Button style={{ position: 'absolute', left: '0%', top: 0, zIndex: 0, width:"50%", height:'100%', opacity: 0 }} onClick={() => currentStory > 0 && setCurrentStory(currentStory - 1)}>PREVIOUS</Button> */}

            </Row>
            }




        </Container>


    );
};

export default MapCam;

